import { CreateInvoiceCustomFieldFormData } from '../../CreateInvoiceCustomFieldForm.types';
import {
  InvoiceCustomFieldFields,
  CreateInvoiceCustomFieldCacheKeys,
  InvoiceCustomFieldLocation
} from '../../../../../invoiceCustomFieldsTypes';
import { InvoiceID } from '../../../../../../invoices/invoicesTypes';
import { CustomFieldLocations } from '../../../../../../customFields/customFieldsTypes';

import { CREATE_INVOICE_CUSTOM_FIELD_QUERY } from '../../../../../queries/createInvoiceCustomField.query';

import { useReactHookForm } from '../../../../../../common/hooks/base/useReactHookForm';
import { useCreateInvoiceCustomField } from '../../../../../hooks/useCreateInvoiceCustomField';

import { createInvoiceCustomFieldFormSchema } from './useCreateInvoiceCustomFieldForm.schema';

interface CreateInvoiceCustomFieldFormProps {
  cacheKeys?: CreateInvoiceCustomFieldCacheKeys;
  location?: InvoiceCustomFieldLocation;
  invoiceId: InvoiceID;
  withoutLabel?: boolean;
}

const defaultInvoiceCustomFieldValues: CreateInvoiceCustomFieldFormData = {
  label: '',
  body: '',
  location: CustomFieldLocations.HEADER
};

function useCreateInvoiceCustomFieldForm({
  cacheKeys,
  location,
  invoiceId,
  withoutLabel
}: CreateInvoiceCustomFieldFormProps) {
  const { control, errors, handleSubmitReactHookForm, register, resetForm } =
    useReactHookForm<CreateInvoiceCustomFieldFormData>({
      defaultValues: { ...defaultInvoiceCustomFieldValues, location },
      isModalForm: true,
      schema: createInvoiceCustomFieldFormSchema(withoutLabel)
    });

  const {
    createInvoiceCustomFieldReset,
    createInvoiceCustomFieldLoading,
    createInvoiceCustomFieldErrorMessage,
    createInvoiceCustomField
  } = useCreateInvoiceCustomField({
    cacheKeys: cacheKeys,
    query: CREATE_INVOICE_CUSTOM_FIELD_QUERY
  });

  return {
    validationErrors: {
      labelValidationError: errors?.label?.message,
      bodyValidationError: errors?.body?.message,
      locationValidationError: errors?.location?.message
    },
    control,
    createInvoiceCustomFieldReset,
    createInvoiceCustomFieldLoading,
    createInvoiceCustomFieldErrorMessage,
    resetCreateInvoiceCustomFieldForm: resetForm,
    handleSubmitReactHookForm,
    handleCreateInvoiceCustomField: handleSubmitReactHookForm({
      dirtyFieldsOnly: false,
      onSubmit: async (data) =>
        await createInvoiceCustomField({
          invoiceId,
          ...data,
          // TODO: remove when backend turns off validation
          ...(withoutLabel
            ? {
                label: data.location
              }
            : {})
        })
    }),
    registerFields: {
      registerLabel: register(InvoiceCustomFieldFields.LABEL),
      registerBody: register(InvoiceCustomFieldFields.BODY),
      registerLocation: register(InvoiceCustomFieldFields.LOCATION)
    }
  };
}

export default useCreateInvoiceCustomFieldForm;
