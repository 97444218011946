import { useCallback, useState } from 'react';

import { useCreateBillingInfoForm } from '../../../../forms/CreateBillingInfoForm/hooks/useCreateBillingInfoForm';

import { BillingInfoID } from '../../../../../billingInfosTypes';
import { InvoicePaymentMethods } from '../../../../../../invoices/invoicesTypes';
import {
  TeamNanoID,
  TeamPreferredPaymentMethod
} from '../../../../../../teams/teamsTypes';

import { BillingInfoCache } from '../../../../../BillingInfoCache';

export type SelectBillingInfoModalSubmitCallbackType = (
  billingInfoId: BillingInfoID,
  paymentMethod: InvoicePaymentMethods
) => void;

interface SelectBillingInfoModalButtonOptions {
  companyNanoId: TeamNanoID;
  defaultBillingInfoId?: BillingInfoID;
  onSubmit: SelectBillingInfoModalSubmitCallbackType;
  preferredPaymentMethod?: TeamPreferredPaymentMethod;
  withCreateForm?: boolean;
  withoutReceiver?: boolean;
}
function useSelectBillingInfoModalButton({
  companyNanoId,
  defaultBillingInfoId,
  onSubmit,
  preferredPaymentMethod,
  withCreateForm = false,
  withoutReceiver = false
}: SelectBillingInfoModalButtonOptions) {
  const {
    control,
    createBillingInfoErrorMessage,
    createBillingInfoLoading,
    createBillingInfoReset,
    handleCreateBillingInfoWithResponse,
    registerFields,
    resetCreateBillingInfoForm,
    validationErrors,
    watchCountry,
    watchPaymentMethod
  } = useCreateBillingInfoForm({
    companyNanoId,
    cacheKey: BillingInfoCache.teamCacheKey(companyNanoId),
    withoutReceiver
  });

  const [showCreateForm, setShowCreateForm] = useState<boolean>(withCreateForm);

  const [billingInfoId, setBillingInfoId] = useState<BillingInfoID | null>(
    defaultBillingInfoId
  );

  const [paymentMethod, setPaymentMethod] = useState<InvoicePaymentMethods>(
    preferredPaymentMethod || InvoicePaymentMethods.CARD
  );

  const handleSubmit = useCallback<() => Promise<void>>(async () => {
    const createdBillingInfoId = showCreateForm
      ? (await handleCreateBillingInfoWithResponse())?.id
      : billingInfoId;

    setShowCreateForm(withCreateForm);

    return onSubmit(createdBillingInfoId?.toString(), paymentMethod);
  }, [
    billingInfoId,
    handleCreateBillingInfoWithResponse,
    onSubmit,
    paymentMethod,
    showCreateForm,
    withCreateForm
  ]);

  const handleClose = useCallback<() => void>(() => {
    createBillingInfoReset();
    setShowCreateForm(withCreateForm);
  }, [createBillingInfoReset, withCreateForm]);

  const handleOpen = useCallback<() => void>(() => {
    resetCreateBillingInfoForm();
    setPaymentMethod(preferredPaymentMethod || InvoicePaymentMethods.CARD);
    setBillingInfoId(defaultBillingInfoId);
  }, [
    defaultBillingInfoId,
    preferredPaymentMethod,
    resetCreateBillingInfoForm
  ]);

  return {
    billingInfoId,
    control,
    createBillingInfoErrorMessage,
    createBillingInfoLoading,
    handleClose,
    handleOpen,
    handleSubmit,
    paymentMethod,
    registerFields,
    resetCreateBillingInfoForm,
    setBillingInfoId,
    setPaymentMethod,
    setShowCreateForm,
    showCreateForm,
    validationErrors,
    watchCountry,
    watchPaymentMethod
  };
}

export default useSelectBillingInfoModalButton;
