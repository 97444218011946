import { useMemo } from 'react';
import filter from 'lodash/filter';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';

import { ClassName } from '../../../../types';
import { CustomFieldLocations } from '../../../customFields/customFieldsTypes';
import {
  InvoiceCustomFieldBody,
  InvoiceCustomFieldLabel,
  InvoiceCustomFieldLocation,
  InvoiceCustomFieldOrder
} from '../../invoiceCustomFieldsTypes';
import isEmpty from 'lodash/isEmpty';

interface InvoiceCustomFieldsListProps {
  wrapperClassName?: ClassName;
  className?: ClassName;
  labelClassName?: ClassName;
  bodyClassName?: ClassName;
  invoiceCustomFields: {
    label: InvoiceCustomFieldLabel;
    body: InvoiceCustomFieldBody;
    location: InvoiceCustomFieldLocation;
    order: InvoiceCustomFieldOrder;
  }[];
  customFieldsLocation: CustomFieldLocations;
}

function InvoiceCustomFieldsList({
  wrapperClassName,
  className,
  labelClassName,
  bodyClassName,
  invoiceCustomFields,
  customFieldsLocation
}: InvoiceCustomFieldsListProps) {
  const invoiceLocationCustomFields = useMemo(
    () =>
      sortBy(
        filter(invoiceCustomFields, ['location', customFieldsLocation]),
        'order'
      ),
    [customFieldsLocation, invoiceCustomFields]
  );

  if (isEmpty(invoiceLocationCustomFields)) return null;

  return (
    <div className={wrapperClassName || 'space-y-1'}>
      {map(invoiceLocationCustomFields, (field, index) => (
        <div key={index} className={className || 'text-xs'}>
          <div className={labelClassName || 'font-semibold'}>
            {field.label}:
          </div>
          <div className={bodyClassName || 'text-gray-600 dark:text-gray-400'}>
            {field.body}
          </div>
        </div>
      ))}
    </div>
  );
}

export default InvoiceCustomFieldsList;
