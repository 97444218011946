import { ClassName, I18nText } from '../../../types';

import { Translate } from '../../Translate';

interface TextFieldProps {
  className?: ClassName;
  i18nLabel: I18nText;
  labelClassName?: ClassName;
  value?: string;
  wrapperClassName?: ClassName;
}
function TextField({
  className,
  i18nLabel,
  labelClassName,
  value,
  wrapperClassName
}: TextFieldProps) {
  return (
    <div className={wrapperClassName || 'mt-2'}>
      <label className={labelClassName || 'text-xs font-semibold'}>
        <Translate id={i18nLabel} />
      </label>
      <div className={className || 'text-xs text-gray-600 dark:text-gray-400'}>
        {value}
      </div>
    </div>
  );
}

export default TextField;
