import {
  FetchTeamsUpsalersFilters,
  FetchTeamsUpsalersSort,
  FetchTeamsUpsalersPage,
  FetchTeamsUpsalersLimit,
  FetchTeamsUpsalersSortTypes
} from '../../teamsUpsalerTypes';

import {
  useInfiniteIndexQuery,
  InfiniteIndexQueryBaseNodeType
} from '../../../common/hooks/base/reactQuery/useInfiniteIndexQuery';

const INITIAL_TEAMS_UPSALERS_FILTERS = {};
const INITIAL_TEAMS_UPSALERS_SORT = [
  FetchTeamsUpsalersSortTypes.CREATED_AT_DESC
];
const INITIAL_TEAMS_UPSALERS_LIMIT = 50;

interface TeamsUpsalersOptions {
  cacheKey: string;
  query: string;
  initialFilters?: FetchTeamsUpsalersFilters;
  initialSort?: FetchTeamsUpsalersSort;
  initialPage?: FetchTeamsUpsalersPage;
  initialLimit?: FetchTeamsUpsalersLimit;
  options?: {
    cacheTime?: number;
    enabled?: boolean;
    enabledPlaceholder?: boolean;
  };
}

const scope = 'teamsUpsalers';

function useTeamsUpsalers<ItemType extends InfiniteIndexQueryBaseNodeType>({
  cacheKey,
  query,
  initialFilters = INITIAL_TEAMS_UPSALERS_FILTERS,
  initialSort = INITIAL_TEAMS_UPSALERS_SORT,
  initialLimit = INITIAL_TEAMS_UPSALERS_LIMIT,
  options = {}
}: TeamsUpsalersOptions) {
  const {
    data,
    items,
    itemsError,
    itemsTotalCount,
    isFetched,
    isLoading,
    isFetchingNextPage,
    isPlaceholderData,
    currentPage,
    currentLimit,
    currentFilters,
    currentSort,
    hasNextPage,
    updateItemCache,
    loadMoreItems,
    filterItems,
    changeItemsFilters,
    clearItemsFilters,
    sortItems,
    limitItems
  } = useInfiniteIndexQuery<ItemType>({
    cacheKey,
    initialFilters,
    initialLimit,
    initialSort,
    options,
    query,
    scope
  });

  return {
    teamsUpsalersData: data,
    teamsUpsalers: items,
    teamsUpsalersError: itemsError,
    teamsUpsalersTotalCount: itemsTotalCount,
    teamsUpsalersFetched: isFetched,
    teamsUpsalersLoading: isLoading,
    teamsUpsalersFetchingNextPage: isFetchingNextPage,
    teamsUpsalersIsPlaceholderData: isPlaceholderData,
    teamsUpsalersFilters: currentFilters,
    teamsUpsalersSort: currentSort,
    teamsUpsalersPage: currentPage,
    teamsUpsalersLimit: currentLimit,
    hasNextTeamsUpsalersPage: hasNextPage,
    updateUserCache: updateItemCache,
    loadMoreTeamsUpsalers: loadMoreItems,
    filterTeamsUpsalers: filterItems,
    changeTeamsUpsalersFilters: changeItemsFilters,
    clearTeamsUpsalersFilters: clearItemsFilters,
    sortTeamsUpsalers: sortItems,
    limitTeamsUpsalers: limitItems
  };
}

export default useTeamsUpsalers;
