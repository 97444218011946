import { IconsEnum } from '../../../../../../assets/icons/types';
import { InvoiceItemsGroupByType } from '../../../../invoicesTypes';

import { InvoiceTableBlockItemType } from '../../hooks/useInvoiceItemsTableBlock';

import { NextPureLinkHelper } from '../../../../../../helpers/links/NextPureLinkHelper';
import { Icon } from '../../../../../../helpers/Icon';

import { TaskPath } from '../../../../../tasks/TaskPath';
import { ProjectPath } from '../../../../../projects/ProjectPath';

interface ItemsGroupHeaderOptions {
  itemsGroupBy: InvoiceItemsGroupByType;
  items: InvoiceTableBlockItemType[];
}

function getItemsGroupHeader({ itemsGroupBy, items }: ItemsGroupHeaderOptions) {
  if (
    itemsGroupBy.projects &&
    itemsGroupBy.tasks &&
    (itemsGroupBy.items || itemsGroupBy.itemCategories || itemsGroupBy.itemTags)
  )
    return (
      <td className="text-xs py-1 tracking-tight" colSpan={4}>
        <div className="flex gap-6 items-center justify-between px-2">
          <div>
            <NextPureLinkHelper
              text={items[0]?.project?.name}
              href={ProjectPath.messages(items[0]?.project?.nanoId)}
              className="hover:underline"
            />

            <Icon
              icon={IconsEnum.ARROW_NARROW_RIGHT_SOLID}
              className="h-5 w-5 inline mx-0.5 shrink-0"
            />

            <NextPureLinkHelper
              text={items[0]?.task?.name}
              href={TaskPath.show(items[0]?.task?.nanoId)}
              className="font-semibold hover:underline"
            />
          </div>
        </div>
      </td>
    );

  if (itemsGroupBy.projects && itemsGroupBy.tasks)
    return (
      <>
        <td className="py-1 font-bold">
          <div className="leading-relaxed text-xs inline-flex gap-1 items-center w-full">
            <NextPureLinkHelper
              text={items[0]?.project?.name}
              href={ProjectPath.messages(items[0]?.project?.nanoId)}
              className="hover:underline"
            />
          </div>
        </td>
        <td className="py-1 pl-2 md:max-w-32 break-words">
          <span>{items[0]?.project?.owner?.fullName}</span>
        </td>
        <td className="py-1 pl-2"></td>
        <td className="py-1 text-right"></td>
      </>
    );

  if (
    itemsGroupBy.projects &&
    (itemsGroupBy.items || itemsGroupBy.itemCategories || itemsGroupBy.itemTags)
  )
    return (
      <td className="font-bold text-xs py-1" colSpan={4}>
        <div className="leading-relaxed text-xs inline-flex gap-1 items-center w-full">
          <NextPureLinkHelper
            text={items[0]?.project?.name}
            href={ProjectPath.messages(items[0]?.project?.nanoId)}
            className="font-semibold hover:underline"
          />
        </div>
      </td>
    );

  if (
    itemsGroupBy.tasks &&
    (itemsGroupBy.items || itemsGroupBy.itemCategories || itemsGroupBy.itemTags)
  )
    return (
      <td className="font-bold text-xs py-1" colSpan={4}>
        <div className="leading-relaxed text-xs inline-flex gap-1 items-center w-full">
          <NextPureLinkHelper
            text={items[0]?.task?.name}
            href={TaskPath.show(items[0]?.task?.nanoId)}
            className="font-semibold hover:underline"
          />
        </div>
      </td>
    );

  return null;
}

export default getItemsGroupHeader;
