import cl from 'classnames';

import { ErrorMessage, ClassName, IsLoading, ID } from '../../../../../types';
import {
  SendToEmailInvoiceFormInvoiceBillingInfoType,
  SendToEmailInvoiceFormTeamType
} from './SendToEmailInvoiceForm.types';
import { IconsEnum } from '../../../../../assets/icons/types';

import { CheckableProfileMaskEmailItem } from './components/CheckableProfileMaskEmailItem';
import { SearchProfileUsersList } from './components/SearchProfileUsersList';
import { CheckableProfileMaskEmailUsersListUserType } from './components/CheckableProfileMaskEmailUsersList';

import { Form } from '../../../../../helpers/Form';
import { TeamAvatar } from '../../../../teams/helpers/TeamAvatar';

import { invoicesKeys } from '../../../../../locales/keys';

interface SendToEmailInvoiceFormProps {
  invoiceBillingInfo: SendToEmailInvoiceFormInvoiceBillingInfoType;
  team: SendToEmailInvoiceFormTeamType;
  form: string;
  innerWrapperClassName?: ClassName;
  isLoading: IsLoading;
  withMaskEmail?: boolean;
  onSearchChange: (
    arg1: { [name: string]: string },
    arg2: string[]
  ) => void | Promise<void>;
  searchValue: string;
  usersMaxTotalCount: number;
  usersFetched: boolean;
  users: CheckableProfileMaskEmailUsersListUserType[];
  usersError: ErrorMessage;
  handleCheckedInvoiceBillingInfo: (value: string, chacked: boolean) => void;
  handleCheckedTeam: (value: string, chacked: boolean) => void;
  handleCheckedUser: (value: string, chacked: boolean) => void;
  checkedInvoiceBillingInfo: boolean;
  checkedTeam: boolean;
  checkedUsers: ID[];
}

function SendToEmailInvoiceForm({
  invoiceBillingInfo,
  team,
  form,
  innerWrapperClassName,
  isLoading,
  withMaskEmail,
  onSearchChange,
  searchValue,
  usersMaxTotalCount,
  usersFetched,
  users,
  usersError,
  handleCheckedInvoiceBillingInfo,
  handleCheckedTeam,
  handleCheckedUser,
  checkedInvoiceBillingInfo,
  checkedTeam,
  checkedUsers
}: SendToEmailInvoiceFormProps) {
  return (
    <Form className="flex-1 overflow-y-auto px-2" id={form}>
      <div className={cl(innerWrapperClassName || 'p-4')}>
        <ul className="-mx-6 sm:-mx-4">
          {invoiceBillingInfo && (
            <CheckableProfileMaskEmailItem
              disabled={isLoading}
              value={invoiceBillingInfo.nanoId}
              checked={checkedInvoiceBillingInfo}
              onCheck={handleCheckedInvoiceBillingInfo}
              i18nTitle={invoicesKeys.invoiceBillingEmail}
              email={invoiceBillingInfo.contactEmail}
              icon={IconsEnum.MAIL}
              withMaskEmail={withMaskEmail}
            />
          )}
          <li>
            <hr className="dark:border-gray-800 my-2 sm:mx-2" />
          </li>
          {team && (
            <CheckableProfileMaskEmailItem
              disabled={isLoading}
              value={team.nanoId}
              checked={checkedTeam}
              onCheck={handleCheckedTeam}
              title={team.name}
              email={team.email}
              avatar={<TeamAvatar team={team} type="main" />}
              withMaskEmail={withMaskEmail}
            />
          )}
          <SearchProfileUsersList
            disabled={isLoading}
            teamName={team?.name}
            onCheck={handleCheckedUser}
            withMaskEmail={withMaskEmail}
            onSearchChange={onSearchChange}
            searchValue={searchValue}
            usersMaxTotalCount={usersMaxTotalCount}
            usersFetched={usersFetched}
            users={users}
            usersError={usersError}
            selectedUserIds={checkedUsers}
          />
        </ul>
      </div>
    </Form>
  );
}

export default SendToEmailInvoiceForm;
