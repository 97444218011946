import { useSelectBillingInfoModalButton } from './hooks/useSelectBillingInfoModalButton';

import { SelectBillingInfoModalContent } from './components/SelectBillingInfoModalContent';

import { BillingInfoID } from '../../../billingInfosTypes';
import {
  ClassName,
  I18nText,
  IsDisabled,
  IsLoading
} from '../../../../../types';
import { InvoicePaymentMethods } from '../../../../invoices/invoicesTypes';
import { SelectBillingInfoModalSubmitCallbackType } from './hooks/useSelectBillingInfoModalButton/useSelectBillingInfoModalButton';
import { TeamNanoID } from '../../../../teams/teamsTypes';

import { FormModalButton } from '../../../../../helpers/buttons/FormModalButton';

import { billingInfosKeys, words } from '../../../../../locales/keys';

const SELECT_BILLING_INFO_FORM = 'select-billing-info-form';

interface SelectBillingInfoModalButtonProps {
  className?: ClassName;
  companyNanoId: TeamNanoID;
  defaultBillingInfoId?: BillingInfoID;
  disabled?: IsDisabled;
  disableSubmitWithEmptyValue?: boolean;
  isLoading?: IsLoading;
  i18nButtonText?: I18nText;
  i18nSubmitText?: I18nText;
  i18nText?: I18nText;
  i18nTitle?: I18nText;
  onSubmit: SelectBillingInfoModalSubmitCallbackType;
  preferredPaymentMethod?: InvoicePaymentMethods;
  withCreateForm?: boolean;
  withPaymentMethod?: boolean;
  withSelectForm?: boolean;
  withoutReceiver?: boolean;
  withoutShowCreateFormOption?: boolean;
}
function SelectBillingInfoModalButton({
  className,
  companyNanoId,
  defaultBillingInfoId,
  disabled,
  disableSubmitWithEmptyValue,
  isLoading,
  i18nButtonText = billingInfosKeys.change,
  i18nSubmitText = words.submit,
  i18nText,
  i18nTitle = billingInfosKeys.change,
  onSubmit,
  preferredPaymentMethod,
  withCreateForm = false,
  withPaymentMethod = false,
  withSelectForm = false,
  withoutReceiver = false,
  withoutShowCreateFormOption = false
}: SelectBillingInfoModalButtonProps) {
  const {
    billingInfoId,
    control,
    createBillingInfoErrorMessage,
    createBillingInfoLoading,
    handleClose,
    handleOpen,
    handleSubmit,
    paymentMethod,
    registerFields,
    setBillingInfoId,
    setPaymentMethod,
    setShowCreateForm,
    showCreateForm,
    validationErrors,
    watchCountry,
    watchPaymentMethod
  } = useSelectBillingInfoModalButton({
    companyNanoId,
    defaultBillingInfoId,
    onSubmit,
    preferredPaymentMethod,
    withCreateForm,
    withoutReceiver
  });

  return (
    <FormModalButton
      className={
        className ||
        'p-0 inline-flex items-center whitespace-nowrap leading-5 focus:ring-base text-current hover:underline underline'
      }
      disabled={!companyNanoId || disabled}
      form={SELECT_BILLING_INFO_FORM}
      i18nSubmitText={i18nSubmitText}
      i18nText={i18nButtonText}
      i18nTitle={i18nTitle}
      isLoading={createBillingInfoLoading || isLoading}
      submitDisabled={
        disableSubmitWithEmptyValue ? !billingInfoId && !showCreateForm : false
      }
      onClose={handleClose}
      onOpen={handleOpen}
      onSubmit={handleSubmit}
    >
      <SelectBillingInfoModalContent
        addressValidationError={validationErrors.addressValidationError}
        avBillingInfoIdValidationError={
          validationErrors.avBillingInfoIdValidationError
        }
        cityValidationError={validationErrors.cityValidationError}
        companyNameValidationError={validationErrors.companyNameValidationError}
        vatNumberValidationError={validationErrors.vatNumberValidationError}
        companyNanoId={companyNanoId}
        contactEmailValidationError={
          validationErrors.contactEmailValidationError
        }
        firstNameValidationError={validationErrors.firstNameValidationError}
        lastNameValidationError={validationErrors.lastNameValidationError}
        contactPhoneValidationError={
          validationErrors.contactPhoneValidationError
        }
        control={control}
        countryValidationError={validationErrors.countryValidationError}
        errorMessage={createBillingInfoErrorMessage}
        form={SELECT_BILLING_INFO_FORM}
        i18nText={i18nText}
        isLoading={createBillingInfoLoading}
        onSelectBillingInfoId={setBillingInfoId}
        onSelectPaymentMethod={setPaymentMethod}
        onSetShowCreateForm={setShowCreateForm}
        paymentMethod={paymentMethod}
        registerAddress={registerFields.registerAddress}
        registerCity={registerFields.registerCity}
        registerCompanyName={registerFields.registerCompanyName}
        registerVatNumber={registerFields.registerVatNumber}
        registerContactEmail={registerFields.registerContactEmail}
        registerFirstName={registerFields.registerFirstName}
        registerLastName={registerFields.registerLastName}
        registerContactPhone={registerFields.registerContactPhone}
        registerZipCode={registerFields.registerZipCode}
        selectedBillingInfoId={billingInfoId}
        stateValidationError={validationErrors.stateValidationError}
        watchCountry={watchCountry}
        watchPaymentMethod={watchPaymentMethod}
        withCreateForm={showCreateForm}
        withPaymentMethod={withPaymentMethod}
        withSelectForm={withSelectForm}
        withoutReceiver={withoutReceiver}
        withoutShowCreateFormOption={withoutShowCreateFormOption}
        zipCodeValidationError={validationErrors.zipCodeValidationError}
      />
    </FormModalButton>
  );
}

export default SelectBillingInfoModalButton;
