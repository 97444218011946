import isString from 'lodash/isString';
import size from 'lodash/size';

const STARS = '***';

function maskEmailPart(emailPart: string): string {
  const partSize = size(emailPart);
  if (partSize < 1) {
    return emailPart;
  }

  if (partSize > 0 && partSize < 5) {
    return emailPart[0] + STARS;
  }

  return (
    emailPart[0] +
    emailPart[1] +
    STARS +
    emailPart[emailPart.length - 2] +
    emailPart[emailPart.length - 1]
  );
}

function maskEmail(email?: string): string | undefined {
  if (isString(email)) {
    const emailParts = email.split('@');
    const maskedEmailParts = emailParts.map((emailPart) =>
      maskEmailPart(emailPart)
    );

    return maskedEmailParts.join('@');
  }

  return undefined;
}

export default maskEmail;
