import React, { useCallback } from 'react';

import { ID, NanoID, UUID } from '../../../../../../../types';

import { ProfileMaskEmailItem } from '../ProfileMaskEmailItem';
import { ProfileMaskEmailItemProps } from '../ProfileMaskEmailItem/ProfileMaskEmailItem';

import { Checkbox } from '../../../../../../../helpers/Checkbox';

type CheckableProfileMaskEmailItemProps = {
  checked?: boolean;
  disabled?: boolean;
  onCheck: (id: ID | NanoID | UUID, checked: boolean) => void;
  value: ID | NanoID | UUID;
} & ProfileMaskEmailItemProps;

function CheckableProfileMaskEmailItem({
  checked,
  disabled,
  onCheck,
  value,
  i18nTitle,
  title,
  email,
  withMaskEmail,
  avatar,
  icon
}: CheckableProfileMaskEmailItemProps) {
  const handleCheck = useCallback(
    (isChecked: boolean) => onCheck?.(value, isChecked),
    [value, onCheck]
  );

  const id = `profile-billing-item-${value}`;

  return (
    <li className="flex items-center group hover:bg-gray-100 dark:hover:bg-gray-800 px-3 py-1 sm:rounded-md">
      <Checkbox
        id={id}
        checked={checked}
        disabled={disabled}
        onChange={handleCheck}
      />
      <label
        htmlFor={id}
        className="ml-2 block text-sm text-gray-900 dark:text-gray-100 flex-shrink-0 flex-1"
      >
        <ProfileMaskEmailItem
          i18nTitle={i18nTitle}
          title={title as never}
          email={email}
          withMaskEmail={withMaskEmail}
          avatar={avatar}
          icon={icon as never}
        />
      </label>
    </li>
  );
}

export default CheckableProfileMaskEmailItem;
