import { ClassName } from '../../../../types';

function addItemsTableCellClassName(index: number, length: number): ClassName {
  if (index === 0 && length === 1) {
    return 'py-2';
  }

  if (index === 0) {
    return 'pt-2 pb-0.5';
  }

  if (index === length - 1) {
    return 'pt-0.5 pb-2';
  }

  return 'py-0.5';
}

export default addItemsTableCellClassName;
