import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';

import { BillingInfoHelperItem } from './BillingInfoHelper.types';
import { ClassName, I18nText } from '../../../../types';
import { IconsEnum } from '../../../../assets/icons/types';

import { useTranslate } from '../../../../common/hooks/useTranslate';

import { Icon } from '../../../../helpers/Icon';
import { Translate } from '../../../../helpers/Translate';

import { formsFields } from '../../../../locales/keys';

interface BillingInfoHelperProps {
  className?: ClassName;
  billingInfo: BillingInfoHelperItem;
  i18nLabel?: I18nText;
  i18nWarning?: I18nText;
}

function BillingInfoHelper({
  className,
  billingInfo,
  i18nWarning,
  i18nLabel
}: BillingInfoHelperProps) {
  const { t } = useTranslate();

  const addressInfo = compact([
    billingInfo?.city,
    billingInfo?.state,
    billingInfo?.zipCode,
    billingInfo?.country
  ]).join(', ');

  const billingInfoArray = compact([
    billingInfo?.companyName,
    billingInfo?.vatNumber &&
      `${t(formsFields.vatNumber)}: ${billingInfo?.vatNumber}`,
    `${billingInfo?.firstName || ''} ${billingInfo?.lastName || ''}`.trim(),
    billingInfo?.address,
    addressInfo,
    billingInfo?.contactPhone,
    billingInfo?.contactEmail
  ]).join('\n');

  return (
    <>
      {i18nLabel && (
        <div className="font-semibold">
          <Translate id={i18nLabel} />
        </div>
      )}

      {i18nWarning && isEmpty(billingInfo) && (
        <div className="text-red-500">
          <Icon
            icon={IconsEnum.EXCLAMATION_CIRCLE}
            className="h-4 w-4 -mt-0.5 mr-1 inline-block"
          />
          <Translate id={i18nWarning} />
        </div>
      )}

      {!isEmpty(billingInfo) && (
        <div
          className={
            className || 'text-gray-600 dark:text-gray-400 whitespace-pre-line'
          }
        >
          {billingInfoArray}
        </div>
      )}
    </>
  );
}

export default BillingInfoHelper;
