import { useCallback } from 'react';

import { UpdateInvoiceCustomFieldFormData } from '../../UpdateInvoiceCustomFieldForm.types';
import {
  InvoiceCustomFieldFields,
  InvoiceCustomFieldID,
  UpdateInvoiceCustomFieldCacheKeys
} from '../../../../../invoiceCustomFieldsTypes';
import { CustomFieldLocations } from '../../../../../../customFields/customFieldsTypes';

import { useReactHookForm } from '../../../../../../common/hooks/base/useReactHookForm';

import { updateInvoiceCustomFieldFormSchema } from './useUpdateInvoiceCustomFieldForm.schema';
import { useUpdateInvoiceCustomField } from '../../../../../hooks/useUpdateInvoiceCustomField';
import { UPDATE_INVOICE_CUSTOM_FIELD_QUERY } from '../../../../../queries/updateInvoiceCustomField.query';

interface UpdateInvoiceCustomFieldFormProps {
  invoiceCustomFieldId: InvoiceCustomFieldID;
  cacheKeys?: UpdateInvoiceCustomFieldCacheKeys;
  withoutLabel?: boolean;
}

const defaultInvoiceCustomFieldValues: UpdateInvoiceCustomFieldFormData = {
  label: '',
  location: CustomFieldLocations.HEADER
};

function useUpdateInvoiceCustomFieldForm({
  invoiceCustomFieldId,
  cacheKeys,
  withoutLabel,
  label,
  body,
  location
}: UpdateInvoiceCustomFieldFormProps & UpdateInvoiceCustomFieldFormData) {
  const {
    control,
    errors,
    handleSubmitReactHookForm,
    register,
    resetForm,
    setValue
  } = useReactHookForm<UpdateInvoiceCustomFieldFormData>({
    defaultValues: {
      ...defaultInvoiceCustomFieldValues,
      label,
      body,
      location
    },
    isModalForm: true,
    schema: updateInvoiceCustomFieldFormSchema(withoutLabel)
  });

  const {
    updateInvoiceCustomFieldReset,
    updateInvoiceCustomFieldLoading,
    updateInvoiceCustomFieldErrorMessage,
    updateInvoiceCustomField
  } = useUpdateInvoiceCustomField({
    cacheKeys: cacheKeys,
    query: UPDATE_INVOICE_CUSTOM_FIELD_QUERY
  });

  const handleInitUpdateInvoiceCustomFieldForm = useCallback(() => {
    setValue(InvoiceCustomFieldFields.LABEL, label);
    setValue(InvoiceCustomFieldFields.BODY, body);
    setValue(InvoiceCustomFieldFields.LOCATION, location);
  }, [body, label, location, setValue]);

  return {
    validationErrors: {
      labelValidationError: errors?.label?.message,
      bodyValidationError: errors?.body?.message,
      locationValidationError: errors?.location?.message
    },
    control,
    updateInvoiceCustomFieldReset,
    updateInvoiceCustomFieldLoading,
    updateInvoiceCustomFieldErrorMessage,
    resetUpdateInvoiceCustomFieldForm: resetForm,
    handleInitUpdateInvoiceCustomFieldForm,
    handleSubmitReactHookForm,
    handleUpdateInvoiceCustomField: handleSubmitReactHookForm({
      dirtyFieldsOnly: false,
      onSubmit: async (data) =>
        updateInvoiceCustomField({
          uuid: invoiceCustomFieldId,
          ...data
        })
    }),
    registerFields: {
      registerLabel: register(InvoiceCustomFieldFields.LABEL),
      registerBody: register(InvoiceCustomFieldFields.BODY),
      registerLocation: register(InvoiceCustomFieldFields.LOCATION)
    }
  };
}

export default useUpdateInvoiceCustomFieldForm;
