import { Fragment } from 'react';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';

import { useTeamsUpsalers } from '../../hooks/useTeamsUpsalers';

import {
  FETCH_SELECT_TEAMS_UPSALERS_QUERY,
  FetchSelectTeamsUpsalersQueryResponse
} from '../../query/fetchSelectTeamsUpsalers.query';

import { ClassName, I18nText } from '../../../../types';
import { TeamUUID } from '../../../teams/teamsTypes';

import { AlertMessage } from '../../../../helpers/AlertMessage';
import { LoadingSkeleton } from '../../../../helpers/LoadingSkeleton';
import { Translate } from '../../../../helpers/Translate';
import { UserLink } from '../../../common/helpers/UserLink';

import { TeamsUpsalerCache } from '../../TeamsUpsalerCache';

interface TeamUpsalersHelperProps {
  valueClassName?: ClassName;
  i18nLabel?: I18nText;
  teamUuid: TeamUUID;
}
function TeamUpsalersHelper({
  i18nLabel,
  teamUuid,
  valueClassName
}: TeamUpsalersHelperProps) {
  const { teamsUpsalers, teamsUpsalersFetched, teamsUpsalersError } =
    useTeamsUpsalers<FetchSelectTeamsUpsalersQueryResponse>({
      query: FETCH_SELECT_TEAMS_UPSALERS_QUERY,
      cacheKey: TeamsUpsalerCache.teamUpsalers(teamUuid),
      initialFilters: { teamId: { eq: teamUuid } }
    });

  if (teamsUpsalersFetched && isEmpty(teamsUpsalers)) {
    return null;
  }

  const teamsUpsalersCount = size(teamsUpsalers);

  return (
    <div className="flex gap-2 text-xs">
      <label className="font-semibold">
        <Translate id={i18nLabel} />:
      </label>

      <div className={valueClassName || 'text-gray-600 dark:text-gray-400'}>
        <LoadingSkeleton loaded={teamsUpsalersFetched} count={1}>
          {teamsUpsalers.map(({ upsaler }, index) => (
            <Fragment key={index}>
              <UserLink user={upsaler} text={upsaler?.fullName} />
              {index < teamsUpsalersCount - 1 && ', '}
            </Fragment>
          ))}
        </LoadingSkeleton>

        <AlertMessage message={teamsUpsalersError} />
      </div>
    </div>
  );
}

export default TeamUpsalersHelper;
