import { useCallback, useEffect, useMemo, useState } from 'react';
import map from 'lodash/map';
import compact from 'lodash/compact';

import {
  SendToEmailInvoiceFields,
  SendToEmailInvoiceFormData
} from '../../SendToEmailInvoiceForm.types';
import {
  InvoiceID,
  InvoiceInvoiceBillingInfoNanoID,
  SendToEmailInvoiceCacheKeys
} from '../../../../../invoicesTypes';
import { UserNanoID } from '../../../../../../users/usersTypes';
import { TeamNanoID } from '../../../../../../teams/teamsTypes';
import { ID } from '../../../../../../../types';

import { sendToEmailInvoiceFormSchema } from './useSendToEmailInvoiceForm.schema';

import { useReactHookForm } from '../../../../../../common/hooks/base/useReactHookForm';
import { useSendToEmailInvoice } from '../../../../../hooks/useSendToEmailInvoice';
import { useTableCheckable } from '../../../../../../../common/hooks/useTableCheckable';
import { useSearchProfileUsersList } from '../../components/SearchProfileUsersList/hooks/useSearchProfileUsersList';

interface SendToEmailInvoiceFormProps {
  invoiceId: InvoiceID;
  teamId?: ID;
  cacheKeys: SendToEmailInvoiceCacheKeys;
}

const defaultBillingInfoValues: SendToEmailInvoiceFormData = {
  invoiceBillingInfoNanoId: '',
  userNanoId: '',
  teamNanoId: ''
};

function useSendToEmailInvoiceForm({
  invoiceId,
  teamId,
  cacheKeys
}: SendToEmailInvoiceFormProps) {
  const { handleSubmitReactHookForm, resetForm, setValue, watch } =
    useReactHookForm<SendToEmailInvoiceFormData>({
      defaultValues: defaultBillingInfoValues,
      isModalForm: true,
      schema: sendToEmailInvoiceFormSchema
    });

  const {
    sendToEmailInvoiceLoading,
    sendToEmailInvoiceErrorMessage,
    sendToEmailInvoice
  } = useSendToEmailInvoice({ cacheKeys });

  const [fullName, setFullName] = useState('');

  const handleSearchChange = useCallback<
    (value: { fullName?: string }) => void
  >(({ fullName }) => {
    setFullName(fullName);
  }, []);

  const { users, usersError, usersFetched, usersTotalCount } =
    useSearchProfileUsersList({
      fullName,
      teamId
    });

  const [usersMaxTotalCount, setUsersMaxTotalCount] = useState(usersTotalCount);

  useEffect(() => {
    if (usersTotalCount > usersMaxTotalCount)
      setUsersMaxTotalCount(usersTotalCount);
  }, [usersTotalCount]);

  const { checkedHash, handleSetCheckedIds } = useTableCheckable({
    items: users
  });

  const handleCheckedInvoiceBillingInfo = useCallback(
    (value: string, checked: boolean) =>
      setValue(
        SendToEmailInvoiceFields.INVOICE_BILLING_INFO_NANO_ID as 'invoiceBillingInfoNanoId',
        checked ? value : ''
      ),
    [setValue]
  );

  const handleCheckedTeam = useCallback(
    (value: string, checked: boolean) =>
      setValue(
        SendToEmailInvoiceFields.TEAM_NANO_ID as 'teamNanoId',
        checked ? value : ''
      ),
    [setValue]
  );

  const checkedUsers = useMemo(
    () => compact(map(checkedHash, (item, key) => (item ? key : undefined))),
    [checkedHash]
  );

  return {
    sendToEmailInvoiceLoading,
    sendToEmailInvoiceErrorMessage,
    resetSendToEmailInvoiceForm: resetForm,
    handleSubmitReactHookForm,
    handleSendToEmailInvoice: handleSubmitReactHookForm({
      dirtyFieldsOnly: false,
      onSubmit: async (data) =>
        sendToEmailInvoice({
          invoiceId,
          invoice: {
            invoiceBillingInfoNanoId:
              data.invoiceBillingInfoNanoId as InvoiceInvoiceBillingInfoNanoID,
            teamNanoId: data.teamNanoId as TeamNanoID,
            userNanoId: checkedUsers as UserNanoID[]
          }
        })
    }),
    handleSetCheckedIds,
    usersMaxTotalCount,
    users,
    usersError,
    usersFetched,
    searchValue: fullName,
    handleSearchChange,
    handleCheckedInvoiceBillingInfo,
    handleCheckedTeam,
    watchInvoiceBillingInfo: watch(
      SendToEmailInvoiceFields.INVOICE_BILLING_INFO_NANO_ID
    ),
    watchTeam: watch(SendToEmailInvoiceFields.TEAM_NANO_ID),
    checkedUsers
  };
}

export default useSendToEmailInvoiceForm;
