import { useMemo } from 'react';
import sumBy from 'lodash/sumBy';

import { InvoiceStatuses } from '../../../../invoicesTypes';
import { InvoiceItemsTableFieldsType } from './useInvoiceItemsTableFields.types';
import { Currencies } from '../../../../../../types';

import { amountMultiply } from '../../../../../../utils/amountMultiply';

interface InvoiceItemsTableFieldsOptions {
  invoice: InvoiceItemsTableFieldsType;
}

function useInvoiceItemsTableFields({
  invoice
}: InvoiceItemsTableFieldsOptions) {
  const primaryInvoiceStatus = invoice?.clientStatus || invoice?.status;

  const secondaryInvoiceStatus =
    invoice?.secondaryInvoice?.clientStatus ||
    invoice?.secondaryInvoice?.status;

  const invoiceStatus =
    secondaryInvoiceStatus === InvoiceStatuses.PAID
      ? secondaryInvoiceStatus
      : primaryInvoiceStatus;

  const prepayment = invoice?.prepayment || 0;

  const prepaymentAmountUsd = invoice?.prepaymentAmount || 0;

  const paymentsUsd =
    (invoice?.status === InvoiceStatuses.PAID && invoice?.amount) +
    (invoice?.secondaryInvoice?.status === InvoiceStatuses.PAID &&
      invoice?.secondaryInvoice?.amount);

  const invoiceTotalTempUsd = useMemo(
    () => sumBy(invoice?.tempItems, (tempItem) => tempItem.price),
    [invoice?.tempItems]
  );

  const invoiceTotalUsd =
    invoice?.status === InvoiceStatuses.DRAFT
      ? invoiceTotalTempUsd
      : invoice?.invoiceTotal;

  const invoiceTotalCurrency =
    invoice?.status === InvoiceStatuses.DRAFT
      ? invoice?.invoiceTempTotalConverted
      : invoice?.invoiceTotalConverted;

  const amountDue =
    invoiceStatus === InvoiceStatuses.PREPAID
      ? invoice?.secondaryInvoice?.amount
      : invoice?.amount;

  const amountDueUsd =
    invoice?.status === InvoiceStatuses.DRAFT ? invoiceTotalUsd : amountDue;

  const prepaymentAmountCurrency = useMemo(
    () =>
      amountMultiply(
        invoiceTotalCurrency,
        prepaymentAmountUsd / (invoiceTotalUsd || 1)
      ),
    [invoiceTotalCurrency, invoiceTotalUsd, prepaymentAmountUsd]
  );

  const amountDueCurrency = useMemo(
    () =>
      amountMultiply(
        invoiceTotalCurrency,
        amountDueUsd / (invoiceTotalUsd || 1)
      ),
    [amountDueUsd, invoiceTotalCurrency, invoiceTotalUsd]
  );

  const paymentsCurrency = useMemo(
    () =>
      amountMultiply(
        invoiceTotalCurrency,
        paymentsUsd / (invoiceTotalUsd || 1)
      ),
    [paymentsUsd, invoiceTotalCurrency, invoiceTotalUsd]
  );

  const isUsd = invoice?.selectedCurrency === Currencies.USD;

  return {
    invoiceStatus,
    payments: isUsd ? paymentsUsd : paymentsCurrency,
    prepayment,
    prepaymentAmount: isUsd ? prepaymentAmountUsd : prepaymentAmountCurrency,
    amountDue: isUsd ? amountDueUsd : amountDueCurrency,
    invoiceTotal: isUsd ? invoiceTotalUsd : invoiceTotalCurrency
  };
}

export default useInvoiceItemsTableFields;
