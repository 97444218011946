import { useEffect } from 'react';

import { ID } from '../../../../../../../../../types';

import { SEARCH_USERS_QUERY } from '../../../../../../../../users/queries/usersSearch.query';

import { useCurrentUser } from '../../../../../../../../../auth/hooks/useAuth';
import { usePaginatedUsers } from '../../../../../../../../users/hooks/usePaginatedUsers';
import { usePreviousValue } from '../../../../../../../../../common/hooks/usePreviousValue';

import { CheckableUsersListItemUserType } from '../../../../../../../../common/modalButtons/InviteUsersModalButton/components/InviteUsersModalBody/components/CheckableUsersListItem';

import { UserCache } from '../../../../../../../../users/UserCache';

interface SearchResultsUsersListOptions {
  fullName?: string;
  teamId?: ID;
}

function useSearchResultsUsersList({
  fullName,
  teamId
}: SearchResultsUsersListOptions) {
  const currentUser = useCurrentUser();

  const { users, usersError, usersFetched, usersTotalCount, filterUsers } =
    usePaginatedUsers<CheckableUsersListItemUserType>({
      cacheKey: UserCache.teamUsersSearchCacheKey(teamId),
      query: SEARCH_USERS_QUERY,
      initialLimit: 80,
      initialPage: 1,
      initialFilters: {
        fullName,
        teamIds: [teamId]
      }
    });

  const prevFullName = usePreviousValue(fullName);

  const prevTeamId = usePreviousValue(teamId);

  useEffect(() => {
    if (prevFullName !== fullName || prevTeamId !== teamId) {
      filterUsers({
        fullName,
        teamIds: [teamId]
      });
    }
  }, [filterUsers, currentUser, fullName, prevFullName, prevTeamId, teamId]);

  return {
    users,
    usersError,
    usersFetched,
    usersTotalCount
  };
}

export default useSearchResultsUsersList;
