import { useCallback } from 'react';

import { BffApiUpdateItemError } from '../../../../types';
import {
  InvoiceID,
  UpdateBillingInfoInvoiceCacheKeys
} from '../../invoicesTypes';
import { BillingInfoID } from '../../../billingInfos/billingInfosTypes';

import { useBffPostQuery } from '../../../common/hooks/base/reactQuery/useBffPostQuery';

import { InvoiceBffUrl } from '../../InvoiceBffUrl';

interface UpdateBillingInfoInvoiceOptions {
  cacheKeys?: UpdateBillingInfoInvoiceCacheKeys;
}

export type UpdateBillingInfoInvoiceInputData = {
  actionOptions: {
    url: string;
  };
} & Pick<UpdateBillingInfoInvoiceInput, 'invoice'>;

export type UpdateBillingInfoInvoiceInput = {
  invoiceId: InvoiceID;
  invoice: {
    billingInfoId: BillingInfoID;
  };
};

export type UpdateBillingInfoInvoiceError = BffApiUpdateItemError;

function useUpdateBillingInfoInvoice({
  cacheKeys
}: UpdateBillingInfoInvoiceOptions) {
  const {
    postQueryData,
    postQueryError,
    postQueryLoading,
    postQueryErrorMessage,
    postQuery,
    postQueryReset
  } = useBffPostQuery<
    UpdateBillingInfoInvoiceInputData,
    unknown,
    UpdateBillingInfoInvoiceError
  >({ cacheKeys });

  const handleUpdateBillingInfoInvoice = useCallback<
    (input: UpdateBillingInfoInvoiceInput) => Promise<unknown>
  >(
    (input) =>
      postQuery({
        invoice: input.invoice,
        actionOptions: {
          url: InvoiceBffUrl.updateBillingInfo(input.invoiceId)
        }
      }),
    [postQuery]
  );

  return {
    updateBillingInfoInvoiceData: postQueryData,
    updateBillingInfoInvoiceError: postQueryError,
    updateBillingInfoInvoiceLoading: postQueryLoading,
    updateBillingInfoInvoiceErrorMessage: postQueryErrorMessage,
    handleUpdateBillingInfoInvoice,
    updateBillingInfoInvoiceReset: postQueryReset
  };
}

export default useUpdateBillingInfoInvoice;
