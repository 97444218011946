import { Control, FieldPath } from 'react-hook-form';
import { useMemo } from 'react';

import { I18nText, IsDisabled } from '../../../../types';

import { SelectOptionType } from '../../../../helpers/Select';
import { SelectField } from '../../../../helpers/FormFields/SelectField';

import {
  customFieldLocationsI18nTexts,
  customFieldLocationsList
} from '../../customFieldsConstants';

interface CustomFieldLocationsSelectFieldProps<FormDataType> {
  name: FieldPath<FormDataType>;
  control: Control<FormDataType>;
  i18nLabel: I18nText;
  i18nPlaceholder: I18nText;
  error?: string | null;
  disabled?: IsDisabled;
}

function CustomFieldLocationsSelectField<FormDataType>({
  control,
  i18nLabel,
  name,
  i18nPlaceholder,
  error,
  disabled
}: CustomFieldLocationsSelectFieldProps<FormDataType>) {
  const options = useMemo<SelectOptionType[]>(
    () =>
      customFieldLocationsList.map((item) => ({
        value: item,
        i18nLabel: customFieldLocationsI18nTexts[item]
      })),
    []
  );

  return (
    <SelectField
      error={error}
      control={control}
      disabled={disabled}
      i18nLabel={i18nLabel}
      name={name}
      labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1"
      options={options}
      i18nPlaceholder={i18nPlaceholder}
    />
  );
}

export default CustomFieldLocationsSelectField;
