import { useCallback, useState } from 'react';
import debounce from 'lodash/debounce';

import {
  InvoiceCustomFieldBody,
  InvoiceCustomFieldID,
  UpdateInvoiceCustomFieldCacheKeys
} from '../../../../invoiceCustomFieldsTypes';

import { UPDATE_INVOICE_CUSTOM_FIELD_QUERY } from '../../../../queries/updateInvoiceCustomField.query';

import {
  UpdateInvoiceCustomFieldInput,
  useUpdateInvoiceCustomField
} from '../../../../hooks/useUpdateInvoiceCustomField';

interface InvoiceCustomFieldEditableFieldoptions {
  customFieldId: InvoiceCustomFieldID;
  customFieldBody?: InvoiceCustomFieldBody;
  cacheKeys?: UpdateInvoiceCustomFieldCacheKeys;
}

function useInvoiceCustomFieldEditableField({
  customFieldId,
  customFieldBody,
  cacheKeys
}: InvoiceCustomFieldEditableFieldoptions) {
  const {
    updateInvoiceCustomFieldLoading,
    updateInvoiceCustomFieldErrorMessage,
    updateInvoiceCustomField
  } = useUpdateInvoiceCustomField({
    cacheKeys: cacheKeys,
    query: UPDATE_INVOICE_CUSTOM_FIELD_QUERY
  });

  const [value, setValue] = useState(customFieldBody);

  const deboundeUpdateInvoiceCustomField = useCallback(
    debounce(
      (data: UpdateInvoiceCustomFieldInput) => updateInvoiceCustomField(data),
      500
    ),
    [updateInvoiceCustomField]
  );

  const handleOnChange = useCallback(
    (value: string) => {
      setValue(value);

      deboundeUpdateInvoiceCustomField({
        uuid: customFieldId,
        body: value
      });
    },
    [customFieldId, deboundeUpdateInvoiceCustomField]
  );

  return {
    value,
    handleOnChange,
    updateInvoiceCustomFieldLoading,
    updateInvoiceCustomFieldErrorMessage
  };
}

export default useInvoiceCustomFieldEditableField;
