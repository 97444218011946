import { useMemo } from 'react';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import filter from 'lodash/filter';

import {
  InvoiceCustomFieldBody,
  InvoiceCustomFieldID,
  InvoiceCustomFieldLabel,
  InvoiceCustomFieldLocation,
  InvoiceCustomFieldOrder
} from '../../invoiceCustomFieldsTypes';
import { CustomFieldLocation } from '../../../customFields/customFieldsTypes';
import { ClassName } from '../../../../types';
import { InvoiceID } from '../../../invoices/invoicesTypes';

import { InvoiceCustomFieldEditableField } from '../InvoiceCustomFieldEditableField';
import { CreateInvoiceCustomFieldModalButton } from '../../components/modalButtons/CreateInvoiceCustomFieldModalButton';

import { InvoiceCache } from '../../../invoices/InvoiceCache';

interface InvoiceCustomFieldEditableFieldsListProps {
  className?: ClassName;
  inputClassName?: ClassName;
  labelClassName?: ClassName;
  buttonsWrapperClassName?: ClassName;
  customFieldsLocation: CustomFieldLocation;
  invoiceCustomFields: {
    id: InvoiceCustomFieldID;
    label: InvoiceCustomFieldLabel;
    body: InvoiceCustomFieldBody;
    location: InvoiceCustomFieldLocation;
    order: InvoiceCustomFieldOrder;
  }[];
  invoiceId?: InvoiceID;
  withoutLabel?: boolean;
  withoutBody?: boolean;
  withoutLocation?: boolean;
  canCreate?: boolean;
  canUpdate?: boolean;
  canDelete?: boolean;
}

function InvoiceCustomFieldEditableFieldsList({
  className,
  inputClassName,
  labelClassName,
  buttonsWrapperClassName,
  customFieldsLocation,
  invoiceCustomFields,
  invoiceId,
  withoutLabel,
  withoutBody,
  withoutLocation,
  canCreate,
  canUpdate,
  canDelete
}: InvoiceCustomFieldEditableFieldsListProps) {
  const invoiceLocationCustomFields = useMemo(
    () =>
      sortBy(
        filter(invoiceCustomFields, ['location', customFieldsLocation]),
        'order'
      ),
    [customFieldsLocation, invoiceCustomFields]
  );

  const cacheKeys = useMemo(() => [InvoiceCache.showCacheKey()], []);

  return (
    <div className={className}>
      {map(invoiceLocationCustomFields, (item) => (
        <InvoiceCustomFieldEditableField
          cacheKeys={cacheKeys}
          inputClassName={inputClassName}
          labelClassName={labelClassName}
          buttonsWrapperClassName={buttonsWrapperClassName}
          key={item.id}
          customFieldId={item.id}
          customFieldLabel={item.label}
          customFieldBody={item.body}
          customFieldLocation={item.location}
          withoutLabel={withoutLabel}
          withoutBody={withoutBody}
          withoutLocation={withoutLocation}
          canUpdate={canUpdate}
          canDelete={canDelete}
        />
      ))}

      {canCreate && (
        <div className="flex align-center text-xs text-blue-600">
          <CreateInvoiceCustomFieldModalButton
            invoiceId={invoiceId}
            customFieldsLocation={customFieldsLocation}
            withoutLabel={withoutLabel}
            withoutLocation={withoutLocation}
            cacheKeys={cacheKeys}
          />
        </div>
      )}
    </div>
  );
}

export default InvoiceCustomFieldEditableFieldsList;
