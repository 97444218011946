import {
  InvoiceCustomFieldID,
  InvoiceCustomFieldUUID,
  DeleteInvoiceCustomFieldCacheKeys,
  DeleteInvoiceCustomFieldGqlQuery
} from '../../invoiceCustomFieldsTypes';

import { useFinDeleteQuery } from '../../../common/hooks/base/reactQuery/useFinDeleteQuery';

interface DeleteInvoiceCustomFieldOptions {
  query: DeleteInvoiceCustomFieldGqlQuery;
  cacheKeys?: DeleteInvoiceCustomFieldCacheKeys;
}

export interface DeleteInvoiceCustomFieldResponse {
  deleteInvoiceCustomField: {
    statusCode: number;
    message: string;
  };
}

export interface DeleteInvoiceCustomFieldInput {
  uuid: InvoiceCustomFieldID | InvoiceCustomFieldUUID;
}

// TODO:
// Add error type for nest gql mutaion response
export type DeleteInvoiceCustomFieldError = null;

const action = 'deleteInvoiceCustomField';

function useDeleteInvoiceCustomField({
  query,
  cacheKeys
}: DeleteInvoiceCustomFieldOptions) {
  const {
    deleteQuery,
    deleteQueryData,
    deleteQueryError,
    deleteQueryErrorMessage,
    deleteQueryLoading,
    deleteQueryReset
  } = useFinDeleteQuery<
    DeleteInvoiceCustomFieldInput,
    DeleteInvoiceCustomFieldResponse,
    DeleteInvoiceCustomFieldError
  >({ action, query, cacheKeys });

  return {
    deleteInvoiceCustomField: deleteQuery,
    deleteInvoiceCustomFieldData: deleteQueryData,
    deleteInvoiceCustomFieldError: deleteQueryError,
    deleteInvoiceCustomFieldErrorMessage: deleteQueryErrorMessage,
    deleteInvoiceCustomFieldLoading: deleteQueryLoading,
    deleteInvoiceCustomFieldReset: deleteQueryReset
  };
}

export default useDeleteInvoiceCustomField;
