import {
  InvoiceCustomFieldID,
  InvoiceCustomFieldUUID,
  UpdateInvoiceCustomFieldCacheKeys,
  UpdateInvoiceCustomFieldGqlQuery
} from '../../invoiceCustomFieldsTypes';

import { useFinUpdateQuery } from '../../../common/hooks/base/reactQuery/useFinUpdateQuery';

interface UpdateInvoiceCustomFieldOptions {
  query: UpdateInvoiceCustomFieldGqlQuery;
  cacheKeys?: UpdateInvoiceCustomFieldCacheKeys;
}

export interface UpdateInvoiceCustomFieldResponse {
  updateInvoiceCustomField: {
    statusCode: number;
    message: string;
  };
}

export interface UpdateInvoiceCustomFieldInput {
  uuid: InvoiceCustomFieldID | InvoiceCustomFieldUUID;
  label?: string;
  body?: string;
  location?: string;
  order?: number;
}

// TODO:
// Add error type for nest gql mutaion response
export type UpdateInvoiceCustomFieldError = null;

const action = 'updateInvoiceCustomField';

function useUpdateInvoiceCustomField<
  UpdateInvoiceCustomFieldRecordType = unknown
>({ query, cacheKeys }: UpdateInvoiceCustomFieldOptions) {
  const {
    updateQuery,
    updateQueryData,
    updateQueryError,
    updateQueryErrorMessage,
    updateQueryLoading,
    updateQueryReset
  } = useFinUpdateQuery<
    UpdateInvoiceCustomFieldInput,
    UpdateInvoiceCustomFieldResponse,
    UpdateInvoiceCustomFieldError,
    UpdateInvoiceCustomFieldRecordType
  >({ action, query, cacheKeys });

  return {
    updateInvoiceCustomField: updateQuery,
    updateInvoiceCustomFieldData: updateQueryData,
    updateInvoiceCustomFieldError: updateQueryError,
    updateInvoiceCustomFieldErrorMessage: updateQueryErrorMessage,
    updateInvoiceCustomFieldLoading: updateQueryLoading,
    updateInvoiceCustomFieldReset: updateQueryReset
  };
}

export default useUpdateInvoiceCustomField;
