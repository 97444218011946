import { useMemo } from 'react';
import compact from 'lodash/compact';
import map from 'lodash/map';
import cl from 'classnames';

import { Toggle } from '../../../../../../helpers/Toggle';
import { Translate } from '../../../../../../helpers/Translate';
import { Checkbox } from '../../../../../../helpers/Checkbox';

import {
  invoicesKeys,
  itemCategoriesKeys,
  itemsKeys,
  projectsKeys,
  tasksKeys
} from '../../../../../../locales/keys';
import { InvoiceItemsGroupBy } from '../../../../invoicesConstants';
import { InvoiceItemsGroupByType } from '../../../../invoicesTypes';

interface InvoiceItemsTableHeaderProps {
  isDraft?: boolean;
  groupBy: InvoiceItemsGroupByType;
  onToggleGroupBy: (value: InvoiceItemsGroupBy) => void;
  myItemsOnly: boolean;
  onChangeMyItemsOnly: (value: boolean) => void;
  withGroupBy?: boolean;
  withGroupByProjectsOption?: boolean;
  withGroupByTasksOption?: boolean;
  withGroupByItemCategoriesOption?: boolean;
  withGroupByItemsOption?: boolean;
  withToggleMyItemsOnly?: boolean;
}

function InvoiceItemsTableHeader({
  isDraft,
  groupBy,
  onToggleGroupBy,
  myItemsOnly,
  onChangeMyItemsOnly,
  withGroupBy,
  withGroupByProjectsOption,
  withGroupByTasksOption,
  withGroupByItemCategoriesOption,
  withGroupByItemsOption,
  withToggleMyItemsOnly
}: InvoiceItemsTableHeaderProps) {
  const options = useMemo(
    () =>
      compact([
        withGroupByProjectsOption && {
          value: InvoiceItemsGroupBy.PROJECTS,
          onClick: () => onToggleGroupBy(InvoiceItemsGroupBy.PROJECTS),
          i18nLabel: projectsKeys.plural
        },
        withGroupByTasksOption && {
          value: InvoiceItemsGroupBy.TASKS,
          onClick: () => onToggleGroupBy(InvoiceItemsGroupBy.TASKS),
          i18nLabel: tasksKeys.plural
        },
        withGroupByItemCategoriesOption && {
          value: InvoiceItemsGroupBy.ITEM_CATEGORIES,
          onClick: () => onToggleGroupBy(InvoiceItemsGroupBy.ITEM_CATEGORIES),
          i18nLabel: itemCategoriesKeys.plural
        },
        withGroupByItemsOption && {
          value: InvoiceItemsGroupBy.ITEMS,
          onClick: () => onToggleGroupBy(InvoiceItemsGroupBy.ITEMS),
          i18nLabel: itemsKeys.plural
        }
      ]),
    [
      onToggleGroupBy,
      withGroupByItemCategoriesOption,
      withGroupByItemsOption,
      withGroupByProjectsOption,
      withGroupByTasksOption
    ]
  );

  return (
    <div className="flex items-center justify-between mb-2 gap-6 text-sm">
      <div className="flex gap-2 items-center">
        {withToggleMyItemsOnly && !isDraft && (
          <>
            <div className="flex justify-start">
              <Toggle value={myItemsOnly} onChange={onChangeMyItemsOnly} />
            </div>
            <div className="text-sm">
              <Translate id={invoicesKeys.myItemsOnly} />
            </div>
          </>
        )}
      </div>
      <div className="flex items-center gap-2">
        {withGroupBy && (
          <>
            {map(options, (option) => (
              <Checkbox
                key={option.value}
                checked={groupBy[option.value]}
                onChange={option.onClick}
                checkboxWrapperClassName={cl(
                  'flex items-center',
                  isDraft && 'dark'
                )}
                i18nLabel={option.i18nLabel}
                labelClassName={isDraft && 'ml-2 block text-sm text-gray-900'}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
}

export default InvoiceItemsTableHeader;
