import { gql } from 'graphql-request';

import {
  SmartContractID,
  SmartContractNanoID,
  SmartContractUUID
} from '../smartContractsTypes';

export interface FetchRedirectSmartContractsQueryResponse {
  id: SmartContractID;
  nanoId: SmartContractNanoID;
  uuid: SmartContractUUID;
}

export const FETCH_REDIRECT_SMART_CONTRACTS_QUERY = gql`
  query RedirectSmartContracts(
    $filters: SmartContractsFilters
    $sort: [SmartContractsSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    smartContracts(
      filters: $filters
      sort: $sort
      limit: $limit
      offset: $offset
    ) {
      nodes {
        id
        nanoId
        uuid
      }
      paginationInfo {
        nextPage
        totalCount
      }
    }
  }
`;
