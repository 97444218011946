import { ReactNode, useMemo } from 'react';

import { IconsEnum } from '../../../../../../../assets/icons/types';
import { I18nText } from '../../../../../../../types';

import { Icon } from '../../../../../../../helpers/Icon';
import { Translate } from '../../../../../../../helpers/Translate';

import { maskEmail } from '../../../../../../../utils/maskEmail';

interface ProfileMaskEmailItemDefaultProps {
  email: string;
  withMaskEmail?: boolean;
}

interface ProfileMaskEmailItemWithoutI18nTitleProps {
  i18nTitle?: never;
  title: string;
}

interface ProfileMaskEmailItemWithI18nTitleProps {
  i18nTitle: I18nText;
  title?: never;
}

interface ProfileMaskEmailItemWithoutAvatarProps {
  avatar?: never;
  icon: IconsEnum;
}

interface ProfileMaskEmailItemWithAvatarProps {
  avatar: ReactNode;
  icon?: never;
}

export type ProfileMaskEmailItemProps = ProfileMaskEmailItemDefaultProps &
  (
    | ProfileMaskEmailItemWithoutI18nTitleProps
    | ProfileMaskEmailItemWithI18nTitleProps
  ) &
  (
    | ProfileMaskEmailItemWithoutAvatarProps
    | ProfileMaskEmailItemWithAvatarProps
  );

function ProfileMaskEmailItem({
  i18nTitle,
  title,
  email,
  withMaskEmail,
  avatar,
  icon
}: ProfileMaskEmailItemProps) {
  const emailText = useMemo(() => {
    return withMaskEmail ? maskEmail(email) : email;
  }, [email, withMaskEmail]);

  return (
    <div className="flex items-center">
      <div>
        {avatar ? (
          avatar
        ) : (
          <div className="inline-flex items-center justify-center h-9 w-9 rounded-full border dark:border-gray-700">
            <Icon icon={icon} className="h-5 w-5" />
          </div>
        )}
      </div>
      <div className="ml-3">
        <p className="text-sm font-medium text-gray-700 dark:text-gray-300 group-hover:text-gray-900 dark:group-hover:text-gray-100">
          {i18nTitle ? <Translate id={i18nTitle} /> : title}
        </p>
        <p className="text-xs text-gray-500 dark:text-gray-500 group-hover:text-gray-700 dark:group-hover:text-gray-400">
          {emailText}
        </p>
      </div>
    </div>
  );
}

export default ProfileMaskEmailItem;
