import { useMemo } from 'react';
import compact from 'lodash/compact';
import map from 'lodash/map';

import {
  InvoiceID,
  InvoiceSmartContractID,
  InvoiceSmartContractProjectOwnerFullName,
  InvoiceSmartContractProjectOwnerNanoID,
  InvoiceSmartContractTaskCreatedAt,
  InvoiceSmartContractTaskName,
  InvoiceSmartContractTaskNanoID,
  InvoiceSmartContractTaskOwnerFullName,
  InvoiceSmartContractTaskOwnerNanoID,
  InvoiceSmartContractTaskProjectCreatedAt,
  InvoiceSmartContractTaskProjectName,
  InvoiceSmartContractTaskProjectNanoID,
  InvoiceStatus,
  InvoiceStatuses
} from '../../../../invoicesTypes';
import { InvoiceItemBaseType } from './useInvoiceItemsTableItems.types';

import { InvoiceItem } from '../useInvoiceItemsTableBlock';

interface InvoiceItemsTableItemsOptions {
  invoice: {
    status: InvoiceStatus;
    childInvoices: {
      id: InvoiceID;
      item: InvoiceItem;
      smartContract: {
        id: InvoiceSmartContractID;
        task: {
          createdAt: InvoiceSmartContractTaskCreatedAt;
          nanoId: InvoiceSmartContractTaskNanoID;
          name: InvoiceSmartContractTaskName;
          owner: {
            nanoId: InvoiceSmartContractTaskOwnerNanoID;
            fullName: InvoiceSmartContractTaskOwnerFullName;
          };
          project: {
            createdAt: InvoiceSmartContractTaskProjectCreatedAt;
            nanoId: InvoiceSmartContractTaskProjectNanoID;
            name: InvoiceSmartContractTaskProjectName;
            owner: {
              nanoId: InvoiceSmartContractProjectOwnerNanoID;
              fullName: InvoiceSmartContractProjectOwnerFullName;
            };
          };
        };
      };
    }[];
    tempItems?: (InvoiceItem & {
      smartContract: {
        id: InvoiceSmartContractID;
        task: {
          createdAt: InvoiceSmartContractTaskCreatedAt;
          nanoId: InvoiceSmartContractTaskNanoID;
          name: InvoiceSmartContractTaskName;
          owner: {
            nanoId: InvoiceSmartContractTaskOwnerNanoID;
            fullName: InvoiceSmartContractTaskOwnerFullName;
          };
          project: {
            createdAt: InvoiceSmartContractTaskProjectCreatedAt;
            nanoId: InvoiceSmartContractTaskProjectNanoID;
            name: InvoiceSmartContractTaskProjectName;
            owner: {
              nanoId: InvoiceSmartContractProjectOwnerNanoID;
              fullName: InvoiceSmartContractProjectOwnerFullName;
            };
          };
        };
      };
    })[];
  };
}

function useInvoiceItemsTableItems({ invoice }: InvoiceItemsTableItemsOptions) {
  const items = useMemo<InvoiceItemBaseType[]>(
    () =>
      compact(
        map(invoice?.childInvoices, (childInvoice) => {
          const item = childInvoice?.item;
          if (!item) return undefined;

          const smartContract = childInvoice.smartContract;
          const task = smartContract?.task;
          const project = task?.project;

          return {
            ...item,
            project,
            task,
            smartContractId: childInvoice.smartContract?.id,
            ownerFullName: project?.owner?.fullName
          };
        })
      ),
    [invoice?.childInvoices]
  );

  const tempItems = useMemo<InvoiceItemBaseType[]>(
    () =>
      map(invoice?.tempItems, (tempItem) => {
        const smartContract = tempItem.smartContract;
        const task = smartContract?.task;
        const project = task?.project;

        return {
          ...tempItem,
          project,
          task,
          smartContractId: smartContract?.id,
          ownerFullName: project?.owner?.fullName
        };
      }),
    [invoice?.tempItems]
  );

  return {
    items: invoice?.status === InvoiceStatuses.DRAFT ? tempItems : items
  };
}

export default useInvoiceItemsTableItems;
