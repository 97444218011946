import { gql } from 'graphql-request';

export const INVOICE_UPDATE_VIEW_SETTINGS_QUERY = gql`
  mutation InvoiceUpdateViewSettings($uuid: ID!, $viewSettings: JSON!) {
    invoiceUpdateViewSettings(
      input: { uuid: $uuid, viewSettings: $viewSettings }
    ) {
      status
    }
  }
`;
