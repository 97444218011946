import { useEffect, useState } from 'react';

import { InvoiceNanoID } from '../../../../invoicesTypes';
import {
  FETCH_REDIRECT_SMART_CONTRACTS_QUERY,
  FetchRedirectSmartContractsQueryResponse
} from '../../../../../smartContracts/queries/fetchRedirectSmartContracts.query';

import { useCurrentUser } from '../../../../../../auth/hooks/useAuth';

import { SmartContractCache } from '../../../../../smartContracts/SmartContractCache';
import { useFinPaginatedSmartContracts } from '../../../../../smartContracts/hooks/useFinPaginatedSmartContracts';

interface InvoiceItemsTableMySmartContractsOptions {
  invoiceNanoId: InvoiceNanoID;
}

const initialLimit = 1000;

function useInvoiceItemsTableMySmartContracts({
  invoiceNanoId
}: InvoiceItemsTableMySmartContractsOptions) {
  const currentUser = useCurrentUser();

  const [myItemsOnly, setMyItemsOnly] = useState(false);

  const { smartContracts, changeSmartContractsFilters, smartContractsFilters } =
    useFinPaginatedSmartContracts<FetchRedirectSmartContractsQueryResponse>({
      query: FETCH_REDIRECT_SMART_CONTRACTS_QUERY,
      cacheKey:
        SmartContractCache.invoiceMySmartContractsCacheKey(invoiceNanoId),
      initialFilters: {
        parentInvoiceNanoId: { eq: invoiceNanoId },
        companyContractorNanoId: { eq: currentUser?.nanoId }
      },
      initialLimit
    });

  useEffect(() => {
    if (smartContractsFilters.parentInvoiceNanoId.eq !== invoiceNanoId) {
      changeSmartContractsFilters({
        parentInvoiceNanoId: { eq: invoiceNanoId }
      });
    }
  }, [invoiceNanoId, changeSmartContractsFilters, smartContractsFilters]);

  return {
    smartContracts,
    myItemsOnly,
    setMyItemsOnly
  };
}

export default useInvoiceItemsTableMySmartContracts;
