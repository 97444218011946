import size from 'lodash/size';

import { ErrorMessage, ID } from '../../../../../../../types';

import { useTranslate } from '../../../../../../../common/hooks/useTranslate';

import {
  CheckableProfileMaskEmailUsersList,
  CheckableProfileMaskEmailUsersListUserType
} from '../CheckableProfileMaskEmailUsersList';

import { TextFilter } from '../../../../../../../helpers/filters/TextFilter';
import { Translate } from '../../../../../../../helpers/Translate';
import { LoadingSkeleton } from '../../../../../../../helpers/LoadingSkeleton';
import { AlertMessage } from '../../../../../../../helpers/AlertMessage';

import { usersKeys, words } from '../../../../../../../locales/keys';

interface SearchResultsUsersListProps {
  disabled?: boolean;
  onCheck?: (id: ID, checked: boolean) => void;
  selectedUserIds?: ID[];
  teamName?: string;
  withMaskEmail?: boolean;
  onSearchChange: (
    arg1: { [name: string]: string },
    arg2: string[]
  ) => void | Promise<void>;
  searchValue: string;
  usersMaxTotalCount: number;
  usersFetched: boolean;
  users: CheckableProfileMaskEmailUsersListUserType[];
  usersError: ErrorMessage;
}

function SearchResultsUsersList({
  disabled,
  selectedUserIds,
  onCheck,
  teamName,
  withMaskEmail,
  onSearchChange,
  searchValue,
  usersMaxTotalCount,
  usersFetched,
  users,
  usersError
}: SearchResultsUsersListProps) {
  const { t } = useTranslate();

  const placeholder = `${t(words.search)} ${teamName} ${t(
    usersKeys.pluralLowerCase
  )}`;

  return (
    <>
      <li>
        <hr className="dark:border-gray-800 my-2 sm:mx-2" />
        {usersMaxTotalCount > 14 && (
          <div className="px-2 mb-2">
            <TextFilter
              disabled={disabled}
              id="search-user-by-name"
              className="bg-gray-100 dark:bg-gray-800 focus-within:border-gray-300 dark:focus-within:border-gray-600 focus-within:shadow-xl focus-within:bg-white dark:focus-within:text-gray-300 focus-within:text-gray-600 sm:max-w-lg relative rounded text-gray-400 border border-transparent w-full"
              inputClassName="text-xs bg-transparent block border-transparent dark:focus:placeholder-gray-500 dark:text-gray-200 focus:outline-none focus:ring-4 focus:ring-blue-300 focus:placeholder-gray-300 rounded h-full pl-6 placeholder-gray-400 pr-2 py-0.5 text-gray-900 w-full"
              iconClassName="h-3 w-3"
              iconWrapperClassName="absolute flex inset-y-0 items-center left-2 pointer-events-none"
              placeholder={teamName ? placeholder : t(words.search)}
              name="fullName"
              onChange={onSearchChange}
              value={searchValue}
            />
          </div>
        )}
      </li>

      <LoadingSkeleton count={2} loaded={usersFetched}>
        {size(users) === 0 ? (
          <div className="text-center my-5">
            <Translate id={words.noResultsYet} />
          </div>
        ) : (
          <CheckableProfileMaskEmailUsersList
            disabled={disabled}
            selectedUserIds={selectedUserIds}
            users={users}
            onCheck={onCheck}
            withMaskEmail={withMaskEmail}
          />
        )}
        <AlertMessage message={usersError} />
      </LoadingSkeleton>
    </>
  );
}

export default SearchResultsUsersList;
