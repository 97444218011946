import cl from 'classnames';

import {
  InvoiceCustomFieldBody,
  InvoiceCustomFieldID,
  InvoiceCustomFieldLabel,
  InvoiceCustomFieldLocation,
  UpdateInvoiceCustomFieldCacheKeys
} from '../../invoiceCustomFieldsTypes';
import { ClassName } from '../../../../types';

import { useInvoiceCustomFieldEditableField } from './hooks/useInvoiceCustomFieldEditableField';

import { UpdateInvoiceCustomFieldModalButton } from '../../components/modalButtons/UpdateInvoiceCustomFieldModalButton';
import { DeleteInvoiceCustomFieldModalButton } from '../../components/modalButtons/DeleteInvoiceCustomFieldModalButton';

import { TextareaAutosizeInput } from '../../../../helpers/TextareaAutosizeInput';

interface CustomFieldEditableFieldProps {
  inputClassName?: ClassName;
  labelClassName?: ClassName;
  buttonsWrapperClassName?: ClassName;
  customFieldId: InvoiceCustomFieldID;
  customFieldLabel: InvoiceCustomFieldLabel;
  customFieldBody?: InvoiceCustomFieldBody;
  customFieldLocation: InvoiceCustomFieldLocation;
  cacheKeys?: UpdateInvoiceCustomFieldCacheKeys;
  withoutLabel?: boolean;
  withoutBody?: boolean;
  withoutLocation?: boolean;
  canUpdate?: boolean;
  canDelete?: boolean;
}

function InvoiceCustomFieldEditableField({
  inputClassName,
  labelClassName,
  buttonsWrapperClassName,
  customFieldId,
  customFieldLabel,
  customFieldBody,
  customFieldLocation,
  cacheKeys,
  withoutLabel,
  withoutBody,
  withoutLocation,
  canUpdate,
  canDelete
}: CustomFieldEditableFieldProps) {
  const { value, handleOnChange } = useInvoiceCustomFieldEditableField({
    customFieldId,
    customFieldBody,
    cacheKeys
  });

  const name = `custom-field-${customFieldLocation}`;

  return (
    <div className="group">
      <div className="flex justify-between items-center gap-4 relative">
        {withoutLabel ? (
          <div></div>
        ) : (
          <label
            htmlFor={name}
            className={
              labelClassName ||
              '-mx-1 px-1 rounded-md text-xs font-semibold relative z-5'
            }
          >
            {customFieldLabel}:
          </label>
        )}
        <div
          className={
            buttonsWrapperClassName ||
            'items-center hidden group-hover:flex group-focus-within:flex absolute right-0 z-5 px-1'
          }
        >
          {canUpdate && (
            <UpdateInvoiceCustomFieldModalButton
              cacheKeys={cacheKeys}
              invoiceCustomFieldId={customFieldId}
              label={customFieldLabel}
              body={customFieldBody}
              location={customFieldLocation}
              withoutLabel={withoutLabel}
              withoutBody={withoutBody}
              withoutLocation={withoutLocation}
            />
          )}
          {canDelete && (
            <DeleteInvoiceCustomFieldModalButton
              cacheKeys={cacheKeys}
              invoiceCustomFieldId={customFieldId}
            />
          )}
        </div>
      </div>
      <TextareaAutosizeInput
        inputWrapperClassName="-mx-2 -mb-1"
        readOnly={!canUpdate}
        name={name}
        onChange={handleOnChange}
        value={value}
        className={cl(
          inputClassName ||
            'w-full text-xs resize-none bg-transparent border-0 px-2 py-1 -mt-1 rounded text-gray-600 dark:text-gray-400',
          canUpdate
            ? 'dark:hover:bg-gray-100/10 dark:focus:bg-gray-100/10 hover:bg-gray-100 focus:bg-gray-100 placeholder-gray-400'
            : 'focus:outline-none focus:bg-transparent hover:bg-transparent'
        )}
        disabled={!canUpdate}
      />
    </div>
  );
}

export default InvoiceCustomFieldEditableField;
