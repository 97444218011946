import { useMutation, useQueryClient } from 'react-query';
import isArray from 'lodash/isArray';

import {
  DeleteItemGqlQuery,
  DeleteItemCacheKeys
} from '../../../../../../types';

import { deleteFinItem } from '../baseActions/deleteFinItem';

import {
  ErrorType,
  parseRequestError
} from '../../../../../../utils/parseRequestError';

interface DeleteQueryOptions<DeleteQueryInput> {
  action: string;
  query: DeleteItemGqlQuery;
  cacheKeys?: DeleteItemCacheKeys;
  onOptimisticDelete?: (input: DeleteQueryInput) => null | (() => void);
}

interface DeleteQueryContext {
  rollback?: () => void;
}

function useFinDeleteQuery<
  DeleteQueryInput,
  DeleteQueryResponse,
  DeleteQueryError extends ErrorType
>({
  action,
  query,
  cacheKeys,
  onOptimisticDelete
}: DeleteQueryOptions<DeleteQueryInput>) {
  const queryClient = useQueryClient();

  const { data, error, isLoading, mutateAsync, reset } = useMutation<
    DeleteQueryResponse,
    DeleteQueryError,
    DeleteQueryInput,
    DeleteQueryContext
  >(
    (queryInput) =>
      deleteFinItem<DeleteQueryInput, DeleteQueryResponse>({
        action,
        query,
        queryInput
      }),
    {
      onMutate: (input) => {
        const rollback = onOptimisticDelete?.(input) || undefined;

        return { rollback };
      },
      onError: (error, variables, context) => {
        context?.rollback?.();
      },
      onSettled: async () => {
        if (isArray(cacheKeys)) {
          cacheKeys.map((eachCacheKey) =>
            queryClient.invalidateQueries(eachCacheKey)
          );
        }
      }
    }
  );

  return {
    deleteQueryData: data,
    deleteQueryError: error,
    deleteQueryLoading: isLoading,
    deleteQueryErrorMessage: parseRequestError(error),
    deleteQuery: mutateAsync,
    deleteQueryReset: reset
  };
}

export default useFinDeleteQuery;
