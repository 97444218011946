import { Control } from 'react-hook-form';

import {
  ErrorMessage,
  IsLoading,
  RegisterFormFieldType
} from '../../../../../types';
import { UpdateCustomFieldFormData } from './UpdateCustomFieldForm.types';
import { CustomFieldFields } from '../../../customFieldsTypes';

import { Form } from '../../../../../helpers/Form';
import { InputField } from '../../../../../helpers/FormFields/InputField';
import { TextareaAutosizeField } from '../../../../../helpers/FormFields/TextareaAutosizeField';
import { CustomFieldLocationsSelectField } from '../../../helpers/CustomFieldLocationsSelectField';

import {
  customFieldsKeys,
  formsFields,
  words
} from '../../../../../locales/keys';

interface UpdateCustomFieldFormProps {
  form: string;
  isLoading: IsLoading;
  control: Control<UpdateCustomFieldFormData>;
  registerLabel: RegisterFormFieldType<HTMLInputElement>;
  labelValidationError: ErrorMessage;
  bodyValidationError: ErrorMessage;
  locationlValidationError: ErrorMessage;
  withoutLabel?: boolean;
  withoutLocation?: boolean;
  withoutBody?: boolean;
}

function UpdateCustomFieldForm({
  form,
  isLoading,
  control,
  registerLabel,
  labelValidationError,
  bodyValidationError,
  locationlValidationError,
  withoutLabel,
  withoutLocation,
  withoutBody
}: UpdateCustomFieldFormProps) {
  return (
    <Form className="flex-1 overflow-y-auto px-2" id={form}>
      <div className="p-4 space-y-4">
        {!withoutLabel && (
          <InputField
            disabled={isLoading}
            error={labelValidationError}
            i18nLabel={formsFields.label}
            inputWrapperClassName="relative mt-1"
            labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
            name={registerLabel.name}
            onChange={registerLabel.onChange}
            ref={registerLabel.ref}
          />
        )}

        {!withoutBody && (
          <div>
            <TextareaAutosizeField<UpdateCustomFieldFormData>
              control={control}
              disabled={isLoading}
              errorMessage={bodyValidationError}
              i18nLabel={formsFields.text}
              className="basic-input resize-none"
              inputWrapperClassName="relative"
              labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1"
              name={CustomFieldFields.BODY}
              maxRows={7}
            />
          </div>
        )}

        {!withoutLocation && (
          <CustomFieldLocationsSelectField<UpdateCustomFieldFormData>
            control={control}
            name={CustomFieldFields.LOCATION}
            i18nLabel={words.location}
            error={locationlValidationError}
            i18nPlaceholder={customFieldsKeys.selectLocation}
          />
        )}
      </div>
    </Form>
  );
}

export default UpdateCustomFieldForm;
