import React, { useCallback } from 'react';

import { ClassName, I18nText, IsDisabled } from '../../../../../types';

import { FetchInvoicesCacheKeys, InvoiceID } from '../../../invoicesTypes';
import { TeamNanoID } from '../../../../teams/teamsTypes';
import { SelectBillingInfoModalSubmitCallbackType } from '../../../../billingInfos/components/modalButtons/SelectBillingInfoModalButton/hooks/useSelectBillingInfoModalButton/useSelectBillingInfoModalButton';

import { useShowToastOnErrorChange } from '../../../../../common/hooks/useShowToastOnErrorChange';
import { useCurrentUser } from '../../../../../auth/hooks/useAuth';

import { useUpdateBillingInfoInvoice } from '../../../hooks/useUpdateBillingInfoInvoice';

import { SelectBillingInfoModalButton } from '../../../../billingInfos/components/modalButtons/SelectBillingInfoModalButton';

import { words } from '../../../../../locales/keys';

import { InvoicesPermissions } from '../../../invoicesConstants';

interface UpdateBillingInfoInvoiceModalButtonProps {
  invoiceId: InvoiceID;
  companyNanoId: TeamNanoID;
  cacheKeys?: FetchInvoicesCacheKeys;
  className?: ClassName;
  disabled?: IsDisabled;
  i18nText?: I18nText;
}

function UpdateBillingInfoInvoiceModalButton({
  invoiceId,
  companyNanoId,
  cacheKeys,
  className,
  disabled,
  i18nText
}: UpdateBillingInfoInvoiceModalButtonProps) {
  const currentUser = useCurrentUser();

  const {
    updateBillingInfoInvoiceLoading,
    updateBillingInfoInvoiceErrorMessage,
    handleUpdateBillingInfoInvoice
  } = useUpdateBillingInfoInvoice({
    cacheKeys
  });

  useShowToastOnErrorChange({ error: updateBillingInfoInvoiceErrorMessage });

  const handleSubmit = useCallback<SelectBillingInfoModalSubmitCallbackType>(
    (billingInfoId) => {
      return handleUpdateBillingInfoInvoice({
        invoiceId,
        invoice: {
          billingInfoId
        }
      });
    },
    [invoiceId, handleUpdateBillingInfoInvoice]
  );

  return (
    <SelectBillingInfoModalButton
      companyNanoId={companyNanoId}
      className={className}
      disabled={disabled || updateBillingInfoInvoiceLoading}
      disableSubmitWithEmptyValue
      isLoading={updateBillingInfoInvoiceLoading}
      i18nButtonText={i18nText}
      i18nSubmitText={words.submit}
      onSubmit={handleSubmit}
      withSelectForm
      withoutReceiver={
        !currentUser.hasPermissions(
          InvoicesPermissions.CHANGE_UPDATE_INVOICE_BILLING_INFO_MODAL_BUTTON_AV_BILLING_INFO
        )
      }
      withoutShowCreateFormOption={
        !currentUser.hasPermissions(
          InvoicesPermissions.CREATE_UPDATE_INVOICE_BILLING_INFO_MODAL_BUTTON_BILLING_INFO
        )
      }
    />
  );
}

export default UpdateBillingInfoInvoiceModalButton;
