import { useFinUpdateQuery } from '../../../common/hooks/base/reactQuery/useFinUpdateQuery';
import {
  InvoiceID,
  InvoiceNanoID,
  InvoiceUUID,
  UpdateViewSettingsInvoiceCacheKeys,
  UpdateViewSettingsInvoiceGqlQuery
} from '../../invoicesTypes';

interface UpdateViewSettingsInvoiceOptions {
  query: UpdateViewSettingsInvoiceGqlQuery;
  cacheKeys?: UpdateViewSettingsInvoiceCacheKeys;
}

export interface UpdateViewSettingsInvoiceResponse {
  updateViewSettingsInvoice: {
    statusCode: number;
    message: string;
  };
}

export interface UpdateViewSettingsInvoiceInput {
  uuid: InvoiceID | InvoiceUUID | InvoiceNanoID;
  viewSettings: Record<string, any>;
}

// TODO:
// Add error type for nest gql mutaion response
export type UpdateViewSettingsInvoiceError = null;

const action = 'invoiceUpdateViewSettings';

function useUpdateViewSettingsInvoice<
  UpdateViewSettingsInvoiceRecordType = unknown
>({ query, cacheKeys }: UpdateViewSettingsInvoiceOptions) {
  const {
    updateQuery,
    updateQueryData,
    updateQueryError,
    updateQueryErrorMessage,
    updateQueryLoading,
    updateQueryReset
  } = useFinUpdateQuery<
    UpdateViewSettingsInvoiceInput,
    UpdateViewSettingsInvoiceResponse,
    UpdateViewSettingsInvoiceError,
    UpdateViewSettingsInvoiceRecordType
  >({ action, query, cacheKeys });

  return {
    updateViewSettingsInvoice: updateQuery,
    updateViewSettingsInvoiceData: updateQueryData,
    updateViewSettingsInvoiceError: updateQueryError,
    updateViewSettingsInvoiceErrorMessage: updateQueryErrorMessage,
    updateViewSettingsInvoiceLoading: updateQueryLoading,
    updateViewSettingsInvoiceReset: updateQueryReset
  };
}

export default useUpdateViewSettingsInvoice;
