import Dinero from 'dinero.js';
import round from 'lodash/round';

function amountMultiply(amount: number, multiplier: number) {
  const dineroAmountDue = Dinero({
    amount: round(amount * 100)
  });

  return dineroAmountDue.multiply(multiplier).getAmount() / 100.0;
}

export default amountMultiply;
