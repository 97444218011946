import { useCallback, useMemo } from 'react';
import isObject from 'lodash/isObject';
import indexOf from 'lodash/indexOf';
import values from 'lodash/values';
import sum from 'lodash/sum';

import {
  FetchInvoicesCacheKeys,
  InvoiceNanoID,
  InvoiceViewSettings
} from '../../../../invoicesTypes';

import {
  InvoiceItemsGroupBy,
  defaultGroupByState,
  singularGroupByKeys
} from '../../../../invoicesConstants';
import { useUpdateViewSettingsInvoice } from '../../../../hooks/useUpdateViewSettingsInvoice';
import { INVOICE_UPDATE_VIEW_SETTINGS_QUERY } from '../../../../queries/invoiceUpdateViewSettings.query';
import map from 'lodash/map';

interface InvoiceItemsTableGroupByProps {
  invoice: {
    nanoId: InvoiceNanoID;
    viewSettings: InvoiceViewSettings;
  };
  cacheKeys?: FetchInvoicesCacheKeys;
}

function useInvoiceItemsTableGroupBy({
  invoice,
  cacheKeys
}: InvoiceItemsTableGroupByProps) {
  const groupBy = useMemo(() => {
    if (
      isObject(invoice.viewSettings) &&
      isObject(invoice.viewSettings.groupBy)
    ) {
      return invoice.viewSettings.groupBy;
    }

    return defaultGroupByState;
  }, [invoice.viewSettings]);

  const { updateViewSettingsInvoice } = useUpdateViewSettingsInvoice({
    query: INVOICE_UPDATE_VIEW_SETTINGS_QUERY,
    cacheKeys: cacheKeys
  });

  const toggleGroupBy = useCallback(
    (key: InvoiceItemsGroupBy) => {
      const newGroupBy = {
        ...groupBy,
        [key]: !groupBy[key]
      };

      if (sum(map(singularGroupByKeys, (skey) => +newGroupBy[skey])) > 1) {
        singularGroupByKeys.forEach((skey) => {
          newGroupBy[skey] = false;
        });

        newGroupBy[key] = true;
      }

      indexOf(values(newGroupBy), true) === -1 &&
        (newGroupBy[InvoiceItemsGroupBy.ITEMS] = true);

      return updateViewSettingsInvoice({
        uuid: invoice.nanoId,
        viewSettings: {
          ...invoice.viewSettings,
          groupBy: newGroupBy
        }
      });
    },
    [groupBy, invoice.nanoId, updateViewSettingsInvoice, invoice.viewSettings]
  );

  return {
    itemsGroupBy: groupBy,
    toggleGroupBy
  };
}

export default useInvoiceItemsTableGroupBy;
