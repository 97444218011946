import * as yup from 'yup';

import { InvoiceCustomFieldFields } from '../../../../../invoiceCustomFieldsTypes';

import { formsErrors } from '../../../../../../../locales/keys';

export const createInvoiceCustomFieldFormSchema = (withoutLabel: boolean) =>
  yup.object({
    [InvoiceCustomFieldFields.LABEL]: withoutLabel
      ? undefined
      : yup.string().required(formsErrors.required),
    [InvoiceCustomFieldFields.LOCATION]: yup
      .string()
      .required(formsErrors.required)
  });
