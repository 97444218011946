import isEmpty from 'lodash/isEmpty';
import compact from 'lodash/compact';

import { AvBillingInfoHelperItem } from './AvBillingInfoHelper.types';
import { ClassName, I18nText } from '../../../../types';
import { IconsEnum } from '../../../../assets/icons/types';

import { Icon } from '../../../../helpers/Icon';
import { Translate } from '../../../../helpers/Translate';
import { formsFields } from '../../../../locales/keys';

interface AvBillingInfoHelperProps {
  className?: ClassName;
  avBillingInfo: AvBillingInfoHelperItem;
  i18nLabel?: I18nText;
  i18nWarning?: I18nText;
}
function AvBillingInfoHelper({
  className,
  avBillingInfo,
  i18nWarning,
  i18nLabel
}: AvBillingInfoHelperProps) {
  const addressInfo = compact([
    avBillingInfo?.city,
    avBillingInfo?.state,
    avBillingInfo?.zipCode,
    avBillingInfo?.country
  ]).join(', ');

  return (
    <>
      {i18nLabel && (
        <div className="font-semibold">
          <Translate id={i18nLabel} />
        </div>
      )}

      {i18nWarning && isEmpty(avBillingInfo) && (
        <div className="text-red-500">
          <Icon
            icon={IconsEnum.EXCLAMATION_CIRCLE}
            className="h-4 w-4 -mt-0.5 mr-1 inline-block"
          />
          <Translate id={i18nWarning} />
        </div>
      )}

      {!isEmpty(avBillingInfo) && (
        <div className={className || 'text-gray-600 dark:text-gray-400'}>
          <div>{avBillingInfo.companyName}</div>
          {avBillingInfo.vatNumber && (
            <div>
              <Translate id={formsFields.vatNumber} />:{' '}
              {avBillingInfo.vatNumber}
            </div>
          )}
          <div>{avBillingInfo.address}</div>
          <div>{addressInfo}</div>
          <div>{avBillingInfo.contactEmail}</div>
          <div>{avBillingInfo.contactPhone}</div>
          <div className="h-2" aria-hidden="true" />
          <div className="whitespace-pre-wrap">{avBillingInfo.bankDetails}</div>
        </div>
      )}
    </>
  );
}

export default AvBillingInfoHelper;
