import { useMemo } from 'react';
import compact from 'lodash/compact';
import map from 'lodash/map';
import groupBy from 'lodash/groupBy';
import join from 'lodash/join';
import sumBy from 'lodash/sumBy';
import sum from 'lodash/sum';
import values from 'lodash/values';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import every from 'lodash/every';
import { Dictionary } from 'ts-essentials';

import {
  InvoiceItemsGroupByType,
  InvoiceSelectedCurrency,
  InvoiceSelectedCurrencyRate
} from '../../../../invoicesTypes';
import { InvoiceItemBaseType } from '../useInvoiceItemsTableItems';
import {
  InvoiceItemsType,
  InvoiceTableBlockItemType
} from '../useInvoiceItemsTableBlock';

import { amountMultiply } from '../../../../../../utils/amountMultiply';
import { getItemsGroupHeader } from '../../utils/getItemsGroupHeader';
import { useTranslate } from '../../../../../../common/hooks/useTranslate';
import { words } from '../../../../../../locales/keys';

interface InvoiceItemsTableIFormattedItemsOptions {
  invoice: {
    selectedCurrency: InvoiceSelectedCurrency;
    selectedCurrencyRate: InvoiceSelectedCurrencyRate;
  };
  items: InvoiceItemBaseType[];
  itemsGroupBy: InvoiceItemsGroupByType;
  isNewItemName?: boolean;
}

function useInvoiceItemsTableIFormattedItems({
  invoice,
  items,
  itemsGroupBy,
  isNewItemName
}: InvoiceItemsTableIFormattedItemsOptions) {
  const { t } = useTranslate();

  const isItems =
    itemsGroupBy.items || itemsGroupBy.itemCategories || itemsGroupBy.itemTags;

  const isOldItems = every(items, (item) => isEmpty(item.itemItemTags));

  const groupedItems = useMemo<Dictionary<InvoiceItemBaseType[]>>(
    () =>
      groupBy(items, (item) =>
        join(
          compact([
            itemsGroupBy.projects && item.project?.nanoId,
            itemsGroupBy.tasks && item.task?.nanoId,
            itemsGroupBy.itemCategories && item.itemCategoryName,
            itemsGroupBy.items &&
              `${
                isOldItems || !isNewItemName
                  ? item.itemTypeName
                  : item.itemItemTags
                      .map((itemItemTag) => itemItemTag.itemTag.id)
                      .sort()
                      .join('-')
              }-${item.price}`
          ]),
          '-'
        )
      ),
    [
      isNewItemName,
      isOldItems,
      items,
      itemsGroupBy.itemCategories,
      itemsGroupBy.items,
      itemsGroupBy.projects,
      itemsGroupBy.tasks
    ]
  );

  const formattedItems = useMemo<InvoiceTableBlockItemType[]>(
    () =>
      map(groupedItems, (items) => {
        const groupPrice = sumBy(items, (item) =>
          invoice.selectedCurrency === item.viewPriceCurrency && item.viewPrice
            ? item.viewPrice
            : amountMultiply(item.price, invoice?.selectedCurrencyRate)
        );

        const itemPrice =
          invoice.selectedCurrency === items[0].viewPriceCurrency &&
          items[0].viewPrice
            ? items[0].viewPrice
            : amountMultiply(items[0]?.price, invoice.selectedCurrencyRate);

        return {
          itemTypeName: items[0]?.itemTypeName,
          itemCategoryName: items[0]?.itemCategoryName,
          itemItemTags: size(items[0]?.itemItemTags)
            ? items[0].itemItemTags
            : [
                {
                  id: '-1',
                  itemTag: { id: '-1', name: t(words.untagged) },
                  position: 0
                }
              ],
          price: !itemsGroupBy.items ? groupPrice : itemPrice,
          quantity: items.length,
          project: items[0]?.project,
          task: items[0]?.task,
          ownerFullName: items[0]?.ownerFullName,
          groupBy:
            sum(values(itemsGroupBy)) > 1
              ? itemsGroupBy.projects && !(itemsGroupBy.tasks && isItems)
                ? items[0]?.project?.nanoId
                : items[0]?.task?.nanoId
              : ''
        };
      }),
    [
      groupedItems,
      invoice.selectedCurrency,
      invoice.selectedCurrencyRate,
      isItems,
      itemsGroupBy,
      t
    ]
  );

  const preparedItems = useMemo<InvoiceItemsType>(
    () =>
      map(
        groupBy(formattedItems, (item) => item.groupBy),
        (items) => ({
          header: getItemsGroupHeader({
            itemsGroupBy,
            items
          }),
          items
        })
      ),
    [formattedItems, itemsGroupBy]
  );

  return {
    preparedItems,
    isItems,
    isOldItems
  };
}

export default useInvoiceItemsTableIFormattedItems;
