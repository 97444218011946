import {
  CreateInvoiceCustomFieldCacheKeys,
  CreateInvoiceCustomFieldGqlQuery
} from '../../invoiceCustomFieldsTypes';

import { useFinCreateQuery } from '../../../common/hooks/base/reactQuery/useFinCreateQuery';

interface CreateInvoiceCustomFieldOptions {
  query: CreateInvoiceCustomFieldGqlQuery;
  cacheKeys?: CreateInvoiceCustomFieldCacheKeys;
}

export interface CreateInvoiceCustomFieldResponse {
  createInvoiceCustomField: {
    statusCode: number;
    message: string;
  };
}

export interface CreateInvoiceCustomFieldInput {
  invoiceId: string;
  label: string;
  body?: string;
  location: string;
  order?: number;
}

// TODO:
// Add error type for nest gql mutaion response
export type CreateInvoiceCustomFieldError = null;

const action = 'createInvoiceCustomField';

function useCreateInvoiceCustomField<
  CreateInvoiceCustomFieldRecordType = unknown
>({ query, cacheKeys }: CreateInvoiceCustomFieldOptions) {
  const {
    createQuery,
    createQueryData,
    createQueryError,
    createQueryErrorMessage,
    createQueryLoading,
    createQueryReset
  } = useFinCreateQuery<
    CreateInvoiceCustomFieldInput,
    CreateInvoiceCustomFieldResponse,
    CreateInvoiceCustomFieldError,
    CreateInvoiceCustomFieldRecordType
  >({ action, query, cacheKeys });

  return {
    createInvoiceCustomField: createQuery,
    createInvoiceCustomFieldData: createQueryData,
    createInvoiceCustomFieldError: createQueryError,
    createInvoiceCustomFieldErrorMessage: createQueryErrorMessage,
    createInvoiceCustomFieldLoading: createQueryLoading,
    createInvoiceCustomFieldReset: createQueryReset
  };
}

export default useCreateInvoiceCustomField;
