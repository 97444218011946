import {
  InvoiceCustomFieldBody,
  InvoiceCustomFieldID,
  InvoiceCustomFieldLabel,
  InvoiceCustomFieldLocation,
  InvoiceCustomFieldOrder
} from '../../invoiceCustomFieldsTypes';
import { CustomFieldLocation } from '../../../customFields/customFieldsTypes';
import { ClassName } from '../../../../types';
import { InvoiceID } from '../../../invoices/invoicesTypes';

import { InvoiceCustomFieldsList } from '../InvoiceCustomFieldsList';
import { InvoiceCustomFieldEditableFieldsList } from '../InvoiceCustomFieldEditableFieldsList';

interface InvoiceCustomFieldsListViewOrEditProps {
  editClassName?: ClassName;
  inputClassName?: ClassName;
  labelClassName?: ClassName;
  buttonsWrapperClassName?: ClassName;
  viewClassName?: ClassName;
  wrapperClassName?: ClassName;
  customFieldsLocation: CustomFieldLocation;
  invoiceCustomFields: {
    id: InvoiceCustomFieldID;
    label: InvoiceCustomFieldLabel;
    body: InvoiceCustomFieldBody;
    location: InvoiceCustomFieldLocation;
    order: InvoiceCustomFieldOrder;
  }[];
  invoiceId: InvoiceID;
  withoutLabel?: boolean;
  withoutBody?: boolean;
  withoutLocation?: boolean;
  canCreate?: boolean;
  canUpdate?: boolean;
  canDelete?: boolean;
}

function InvoiceCustomFieldsListViewOrEdit({
  editClassName,
  inputClassName,
  labelClassName,
  buttonsWrapperClassName,
  viewClassName,
  wrapperClassName,
  customFieldsLocation,
  invoiceCustomFields,
  invoiceId,
  withoutLabel,
  withoutBody,
  withoutLocation,
  canCreate,
  canUpdate,
  canDelete
}: InvoiceCustomFieldsListViewOrEditProps) {
  return canCreate || canUpdate || canDelete ? (
    <InvoiceCustomFieldEditableFieldsList
      inputClassName={inputClassName}
      labelClassName={labelClassName}
      buttonsWrapperClassName={buttonsWrapperClassName}
      className={editClassName}
      customFieldsLocation={customFieldsLocation}
      invoiceId={invoiceId}
      invoiceCustomFields={invoiceCustomFields}
      withoutLabel={withoutLabel}
      withoutBody={withoutBody}
      withoutLocation={withoutLocation}
      canCreate={canCreate}
      canUpdate={canUpdate}
      canDelete={canDelete}
    />
  ) : (
    <InvoiceCustomFieldsList
      wrapperClassName={wrapperClassName}
      className={viewClassName}
      invoiceCustomFields={invoiceCustomFields}
      customFieldsLocation={customFieldsLocation}
    />
  );
}

export default InvoiceCustomFieldsListViewOrEdit;
