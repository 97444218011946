import map from 'lodash/map';
import includes from 'lodash/includes';

import { ID } from '../../../../../../../types';
import { UserAvatar } from '../../../../../../common/helpers/UserAvatar';
import {
  UserID,
  UserNanoID,
  UserUUID
} from '../../../../../../users/usersTypes';
import { CheckableProfileMaskEmailItem } from '../CheckableProfileMaskEmailItem';

export interface CheckableProfileMaskEmailUsersListUserType {
  id: UserID;
  uuid: UserUUID;
  nanoId: UserNanoID;
  fullName: string;
  email: string;
  image: {
    file: string;
  };
}

type CheckableProfileMaskEmailUsersListProps = {
  selectedUserIds: ID[];
  users: CheckableProfileMaskEmailUsersListUserType[];
  onCheck?: (id: ID, checked: boolean) => void;
  withMaskEmail?: boolean;
  disabled?: boolean;
};

function CheckableProfileMaskEmailUsersList({
  selectedUserIds,
  users,
  onCheck,
  withMaskEmail,
  disabled
}: CheckableProfileMaskEmailUsersListProps) {
  return (
    <>
      {map(users, (user) => (
        <CheckableProfileMaskEmailItem
          key={user.uuid}
          checked={includes(selectedUserIds, user.nanoId)}
          value={user.nanoId}
          onCheck={onCheck}
          title={user.fullName}
          email={user.email}
          avatar={<UserAvatar user={user} type="main" />}
          withMaskEmail={withMaskEmail}
          disabled={disabled}
        />
      ))}
    </>
  );
}

export default CheckableProfileMaskEmailUsersList;
