import {
  TeamEmail,
  TeamImageFile,
  TeamName,
  TeamNanoID,
  TeamUUID
} from '../../../../teams/teamsTypes';
import {
  InvoiceInvoiceBillingInfoContactEmail,
  InvoiceInvoiceBillingInfoNanoID
} from '../../../invoicesTypes';

export interface SendToEmailInvoiceFormData {
  invoiceBillingInfoNanoId?: string;
  userNanoId?: string;
  teamNanoId?: string;
}

export interface SendToEmailInvoiceFormInvoiceBillingInfoType {
  nanoId: InvoiceInvoiceBillingInfoNanoID;
  contactEmail: InvoiceInvoiceBillingInfoContactEmail;
}

export interface SendToEmailInvoiceFormTeamType {
  uuid: TeamUUID;
  nanoId: TeamNanoID;
  email: TeamEmail;
  name: TeamName;
  image: {
    file: TeamImageFile;
  } | null;
}

export enum SendToEmailInvoiceFields {
  INVOICE_BILLING_INFO_NANO_ID = 'invoiceBillingInfoNanoId',
  USER_NANO_ID = 'userNanoId',
  TEAM_NANO_ID = 'teamNanoId'
}
