import join from 'lodash/join';

import { PathUrl } from '../../types';

import { BFF_API_PATH } from '../../config';

const bffApiUrl =
  typeof window === 'undefined'
    ? null
    : join([window.location.origin, BFF_API_PATH], '');

function downloadFileFromBffApi(url: PathUrl) {
  const link = document.createElement('a');
  link.href = join([bffApiUrl, url], '/');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export default downloadFileFromBffApi;
