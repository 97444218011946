import { IconsEnum } from '../../../../../assets/icons/types';
import { ClassName } from '../../../../../types';
import {
  InvoiceCustomFieldBody,
  InvoiceCustomFieldID,
  InvoiceCustomFieldLabel,
  InvoiceCustomFieldLocation,
  UpdateInvoiceCustomFieldCacheKeys
} from '../../../invoiceCustomFieldsTypes';

import { UpdateCustomFieldForm } from '../../../../customFields/components/forms/UpdateCustomFieldForm';
import { useUpdateInvoiceCustomFieldForm } from '../../forms/UpdateInvoiceCustomFieldForm/hooks/useUpdateInvoiceCustomFieldForm';

import { FormModalButton } from '../../../../../helpers/buttons/FormModalButton';
import { AlertMessage } from '../../../../../helpers/AlertMessage';

import { customFieldsKeys, words } from '../../../../../locales/keys';

const form = 'update-custom-field-form';

interface UpdateInvoiceCustomFieldModalButtonProps {
  className?: ClassName;
  invoiceCustomFieldId: InvoiceCustomFieldID;
  cacheKeys?: UpdateInvoiceCustomFieldCacheKeys;
  withoutLabel?: boolean;
  withoutBody?: boolean;
  withoutLocation?: boolean;
  label: InvoiceCustomFieldLabel;
  body?: InvoiceCustomFieldBody;
  location: InvoiceCustomFieldLocation;
}

function UpdateInvoiceCustomFieldModalButton({
  className,
  invoiceCustomFieldId,
  cacheKeys,
  withoutLabel,
  withoutLocation,
  withoutBody,
  label,
  body,
  location
}: UpdateInvoiceCustomFieldModalButtonProps) {
  const {
    validationErrors,
    registerFields,
    control,
    updateInvoiceCustomFieldReset,
    updateInvoiceCustomFieldLoading,
    updateInvoiceCustomFieldErrorMessage,
    handleInitUpdateInvoiceCustomFieldForm,
    handleUpdateInvoiceCustomField
  } = useUpdateInvoiceCustomFieldForm({
    invoiceCustomFieldId,
    cacheKeys,
    withoutLabel,
    label,
    body,
    location
  });

  return (
    <FormModalButton
      form={form}
      className={
        className ||
        'py-0.5 pl-0.5 pr-0.5 rounded inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base text-gray-500 hover:text-gray-700'
      }
      icon={IconsEnum.PENCIL}
      iconClassName="h-4 w-4"
      i18nSubmitText={words.update}
      i18nTitle={customFieldsKeys.editField}
      tooltipI18nText={words.edit}
      isLoading={updateInvoiceCustomFieldLoading}
      onOpen={handleInitUpdateInvoiceCustomFieldForm}
      onClose={updateInvoiceCustomFieldReset}
      onSubmit={handleUpdateInvoiceCustomField}
    >
      <UpdateCustomFieldForm
        form={form}
        control={control}
        isLoading={false}
        registerLabel={registerFields.registerLabel}
        labelValidationError={validationErrors.labelValidationError}
        bodyValidationError={validationErrors.bodyValidationError}
        locationlValidationError={validationErrors.locationValidationError}
        withoutLabel={withoutLabel}
        withoutLocation={withoutLocation}
        withoutBody={withoutBody}
      />

      <AlertMessage
        addClassName="m-4"
        message={updateInvoiceCustomFieldErrorMessage}
      />
    </FormModalButton>
  );
}

export default UpdateInvoiceCustomFieldModalButton;
