import { ClassName, I18nText, IsDisabled } from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';
import { FetchInvoicesCacheKeys, InvoiceID } from '../../../invoicesTypes';

import { useSendToEmailInvoiceForm } from '../../forms/SendToEmailInvoiceForm/hooks/useSendToEmailInvoiceForm';

import {
  SendToEmailInvoiceForm,
  SendToEmailInvoiceFormInvoiceBillingInfoType,
  SendToEmailInvoiceFormTeamType
} from '../../forms/SendToEmailInvoiceForm';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { FormModalButton } from '../../../../../helpers/buttons/FormModalButton';
import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';

import { words } from '../../../../../locales/keys';
import { useCurrentUser } from '../../../../../auth/hooks/useAuth';
import { InvoicesPermissions } from '../../../invoicesConstants';

const SEND_TO_EMAIL_INVOICE_FORM = 'send-to-email-invoice-form';

interface SendToEmailInvoiceModalButtonProps {
  cacheKeys?: FetchInvoicesCacheKeys;
  className?: ClassName;
  disabled?: IsDisabled;
  i18nText?: I18nText;
  i18nTextClassName?: ClassName;
  invoiceId: InvoiceID;
  invoiceBillingInfo: SendToEmailInvoiceFormInvoiceBillingInfoType;
  team: SendToEmailInvoiceFormTeamType;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  tooltipPlacement?: TooltipPlacement;
  tooltipI18nText?: I18nText;
}

function SendToEmailInvoiceModalButton({
  cacheKeys,
  className,
  disabled,
  i18nText,
  i18nTextClassName,
  invoiceId,
  invoiceBillingInfo,
  team,
  icon,
  iconClassName,
  tooltipPlacement,
  tooltipI18nText
}: SendToEmailInvoiceModalButtonProps) {
  const {
    resetSendToEmailInvoiceForm,
    handleSendToEmailInvoice,
    sendToEmailInvoiceLoading,
    sendToEmailInvoiceErrorMessage,
    usersMaxTotalCount,
    users,
    usersError,
    usersFetched,
    searchValue,
    handleSearchChange,
    handleSetCheckedIds,
    handleCheckedInvoiceBillingInfo,
    handleCheckedTeam,
    checkedUsers,
    watchInvoiceBillingInfo,
    watchTeam
  } = useSendToEmailInvoiceForm({
    invoiceId,
    cacheKeys,
    teamId: team?.uuid
  });

  const currentUser = useCurrentUser();

  return (
    <FormModalButton
      className={
        className ||
        'py-1.5 pl-3 pr-3 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base border border-gray-300 dark:border-gray-700 shadow-sm bg-white dark:bg-gray-800 text-gray-500 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700 self-start ml-2 my-1'
      }
      modalSize="md"
      disabled={disabled}
      form={SEND_TO_EMAIL_INVOICE_FORM}
      i18nSubmitText={words.send}
      icon={icon}
      iconClassName={iconClassName}
      tooltipPlacement={tooltipPlacement}
      tooltipI18nText={tooltipI18nText}
      i18nText={i18nText}
      i18nTextClassName={i18nTextClassName}
      i18nTitle={words.sendToEmail}
      isLoading={sendToEmailInvoiceLoading}
      onOpen={resetSendToEmailInvoiceForm}
      onSubmit={handleSendToEmailInvoice}
    >
      <SendToEmailInvoiceForm
        invoiceBillingInfo={invoiceBillingInfo}
        team={team}
        form={SEND_TO_EMAIL_INVOICE_FORM}
        isLoading={sendToEmailInvoiceLoading}
        withMaskEmail={
          !currentUser.hasPermissions(
            InvoicesPermissions.READ_SEND_TO_EMAIL_PROFILE_EMAIL_FIELD
          )
        }
        usersMaxTotalCount={usersMaxTotalCount}
        users={users}
        usersError={usersError}
        usersFetched={usersFetched}
        searchValue={searchValue}
        onSearchChange={handleSearchChange}
        checkedInvoiceBillingInfo={watchInvoiceBillingInfo !== ''}
        checkedTeam={watchTeam !== ''}
        checkedUsers={checkedUsers}
        handleCheckedInvoiceBillingInfo={handleCheckedInvoiceBillingInfo}
        handleCheckedTeam={handleCheckedTeam}
        handleCheckedUser={handleSetCheckedIds}
      />

      <div className="px-4">
        <AlertMessage message={sendToEmailInvoiceErrorMessage} />
      </div>
    </FormModalButton>
  );
}

export default SendToEmailInvoiceModalButton;
