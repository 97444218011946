import { AvBillingInfoPaymentMethods } from '../../avBillingInfosTypes';
import { ClassName } from '../../../../types';

import { Translate } from '../../../../helpers/Translate';

import { avBillingInfoI18nTextPaymentMethods } from '../../avBillingInfosConstants';
import { words } from '../../../../locales/keys';

interface AvBillinIngoPaymentMethodHelperProps {
  paymentMethod: AvBillingInfoPaymentMethods;
  valueClassName?: ClassName;
}

function AvBillinIngoPaymentMethodHelper({
  paymentMethod,
  valueClassName
}: AvBillinIngoPaymentMethodHelperProps) {
  return (
    <div className="flex gap-2 text-xs">
      <label className="font-semibold">
        <Translate id={words.paymentMethod} />
        {': '}
      </label>
      <div className={valueClassName || 'text-gray-600 dark:text-gray-400'}>
        <Translate id={avBillingInfoI18nTextPaymentMethods[paymentMethod]} />
      </div>
    </div>
  );
}

export default AvBillinIngoPaymentMethodHelper;
