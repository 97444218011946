import { useMemo } from 'react';
import find from 'lodash/find';
import filter from 'lodash/filter';

import { ItemCategoryName } from '../../../../../itemCategories/itemCategoriesTypes';
import {
  ItemTagID,
  ItemTagName,
  ItemTagPosition
} from '../../../../../itemTags/itemTagsTypes';
import {
  ItemPrice,
  ItemSmartContractID,
  ItemViewPrice,
  ItemViewPriceCurrency
} from '../../../../../items/itemsTypes';
import { ItemTypeName } from '../../../../../itemTypes/itemTypesTypes';
import { FetchInvoicesCacheKeys } from '../../../../invoicesTypes';
import { ID } from '../../../../../../types';
import { InvoiceItemsTableBlockType } from './useInvoiceItemsTableBlock.types';
import { InvoiceItemBaseType } from '../useInvoiceItemsTableItems';

import { useInvoiceItemsTableFields } from '../useInvoiceItemsTableFields';
import { useInvoiceItemsTableItems } from '../useInvoiceItemsTableItems';
import { useInvoiceItemsTableIFormattedItems } from '../useInvoiceItemsTableIFormattedItems';
import { useInvoiceItemsTableMySmartContracts } from '../useInvoiceItemsTableMySmartContracts';
import { useInvoiceItemsTableGroupBy } from '../useInvoiceItemsTableGroupBy';

import { InvoiceItemGroup, InvoiceItemType } from '../../../InvoiceItemsTable';

import { projectsKeys, tasksKeys } from '../../../../../../locales/keys';

export type InvoiceItem = {
  itemTypeName: ItemTypeName;
  itemCategoryName: ItemCategoryName;
  price: ItemPrice;
  viewPrice: ItemViewPrice;
  viewPriceCurrency: ItemViewPriceCurrency;
  smartContractId: ItemSmartContractID;
  itemItemTags: {
    id: ID;
    itemTag: {
      id: ItemTagID;
      name: ItemTagName;
    };
    position: ItemTagPosition;
  }[];
};

export type InvoiceItemsType = InvoiceItemGroup[];

export type InvoiceTableBlockItemType = InvoiceItemType & {
  groupBy: string;
};

interface InvoiceItemsTableBlockOptions {
  invoice: InvoiceItemsTableBlockType;
  cacheKeys?: FetchInvoicesCacheKeys;
  isNewItemName?: boolean;
}

function useInvoiceItemsTableBlock({
  invoice,
  cacheKeys,
  isNewItemName
}: InvoiceItemsTableBlockOptions) {
  const { itemsGroupBy, toggleGroupBy } = useInvoiceItemsTableGroupBy({
    invoice,
    cacheKeys
  });

  const {
    invoiceStatus,
    prepayment,
    prepaymentAmount,
    payments,
    amountDue,
    invoiceTotal
  } = useInvoiceItemsTableFields({ invoice });

  const { items } = useInvoiceItemsTableItems({ invoice });

  const {
    smartContracts: mySmartContracts,
    myItemsOnly,
    setMyItemsOnly
  } = useInvoiceItemsTableMySmartContracts({
    invoiceNanoId: invoice.nanoId
  });

  const filteredItems = useMemo<InvoiceItemBaseType[]>(
    () =>
      filter(
        items,
        (item) =>
          !(
            myItemsOnly &&
            !find(
              mySmartContracts,
              (mySmartContract) => mySmartContract.id == item.smartContractId
            )
          )
      ),
    [items, myItemsOnly, mySmartContracts]
  );

  const { preparedItems, isItems, isOldItems } =
    useInvoiceItemsTableIFormattedItems({
      invoice,
      items: filteredItems,
      itemsGroupBy,
      isNewItemName
    });

  return {
    itemsGroupBy,
    toggleGroupBy,
    groupedItems: preparedItems,
    myItemsOnly,
    setMyItemsOnly,
    i18nFirstColName:
      !isItems && (itemsGroupBy.tasks ? tasksKeys.name : projectsKeys.name),
    invoiceStatus,
    prepayment,
    prepaymentAmount,
    payments,
    amountDue,
    invoiceTotal,
    isOldItems
  };
}

export default useInvoiceItemsTableBlock;
