import { useCallback } from 'react';

import { BffApiUpdateItemError } from '../../../../types';
import {
  InvoiceID,
  InvoiceInvoiceBillingInfoNanoID,
  SendToEmailInvoiceCacheKeys
} from '../../invoicesTypes';
import { UserNanoID } from '../../../users/usersTypes';
import { TeamNanoID } from '../../../teams/teamsTypes';

import { useBffPostQuery } from '../../../common/hooks/base/reactQuery/useBffPostQuery';

import { InvoiceBffUrl } from '../../InvoiceBffUrl';

export type SendToEmailInvoiceInvoiceInputData = {
  actionOptions: {
    url: string;
  };
} & Pick<SendToEmailInvoiceInvoiceInput, 'invoice'>;

export type SendToEmailInvoiceInvoiceInput = {
  invoiceId: InvoiceID;
  invoice: {
    invoiceBillingInfoNanoId?: InvoiceInvoiceBillingInfoNanoID;
    userNanoId?: UserNanoID | UserNanoID[];
    teamNanoId?: TeamNanoID;
  };
};

export type SendToEmailInvoiceInvoiceError = BffApiUpdateItemError;

export type SendToEmailInvoiceInvoiceResponseType = unknown;

interface SendToEmailInvoiceOprions {
  cacheKeys: SendToEmailInvoiceCacheKeys;
}

function useSendToEmailInvoice({ cacheKeys }: SendToEmailInvoiceOprions) {
  const {
    postQueryData,
    postQueryError,
    postQueryLoading,
    postQueryErrorMessage,
    postQuery,
    postQueryReset
  } = useBffPostQuery<
    SendToEmailInvoiceInvoiceInputData,
    SendToEmailInvoiceInvoiceResponseType,
    SendToEmailInvoiceInvoiceError
  >({ cacheKeys });

  const sendToEmailInvoice = useCallback<
    (input: SendToEmailInvoiceInvoiceInput) => Promise<unknown>
  >(
    (input) =>
      postQuery({
        invoice: input.invoice,
        actionOptions: {
          url: InvoiceBffUrl.sendToEmail(input.invoiceId)
        }
      }),
    [postQuery]
  );

  return {
    sendToEmailInvoiceData: postQueryData,
    sendToEmailInvoiceError: postQueryError,
    sendToEmailInvoiceLoading: postQueryLoading,
    sendToEmailInvoiceErrorMessage: postQueryErrorMessage,
    sendToEmailInvoice,
    sendToEmailInvoiceReset: postQueryReset
  };
}

export default useSendToEmailInvoice;
