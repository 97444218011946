import { useCallback } from 'react';

import { InvoiceID } from '../../invoicesTypes';

import { InvoiceBffUrl } from '../../InvoiceBffUrl';
import { downloadFileFromBffApi } from '../../../../utils/downloadFileFromBffApi';

export type DownloadInvoiceInput = {
  invoiceId: InvoiceID;
};

function useDownloadInvoicePdf() {
  const downloadInvoice = useCallback<(input: DownloadInvoiceInput) => void>(
    (input) =>
      downloadFileFromBffApi(InvoiceBffUrl.downloadPdf(input.invoiceId)),
    []
  );

  return { downloadInvoice };
}

export default useDownloadInvoicePdf;
